import axios from "axios";
import { DeviceInfo } from "../models/DeviceInfo";
import { Endpoints } from "../models/Endpoints";

export async function registerTabletEndpoint(
  title: string,
  tenant_id: number,
  building_id: number,
  floor_id: number,
  space_id: number,
  device_info: string,
  tablet_type: string,
  userToken: string,
  endpoints: Endpoints
) {
  console.log(
    title,
    tenant_id,
    building_id,
    floor_id,
    space_id,
    tablet_type,
    device_info,
    userToken
  );
  return axios.post(
    endpoints.tablet_endpoints.register_tablet,
    {
      title,
      tenant_id,
      building_id,
      floor_id,
      space_id,
      tablet_type,
      device_info,
    },
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    }
  );
}
