import { useEffect, useState } from "react";
import { Text, View } from "react-native";
import { useTailwind } from "tailwind-rn";

const DateTime = () => {
  const tailwind = useTailwind();

  const [date, setDate] = useState(new Date());

  useEffect(() => {
    let timer = setInterval(() => {
      setDate(new Date());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const monthTitles = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  function pad(value) {
    if (value < 10) {
      return "0" + value;
    } else {
      return value;
    }
  }

  return (
    <View style={tailwind("w-full h-[120px] flex flex-row justify-between	")}>
      <Text style={tailwind("text-white p-9 text-xl font-inter font-semibold")}>
        {date.getDate()} {monthTitles[date.getMonth()]}
      </Text>
      <Text
        style={tailwind(
          "text-white px-9 py-8 text-4xl font-inter font-semibold"
        )}
      >
        {pad(date.getHours())}:{pad(date.getMinutes())}
      </Text>
    </View>
  );
};

export default DateTime;
