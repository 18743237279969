import { useContext, useState, useEffect } from "react";
import { SafeAreaView, View, Platform } from "react-native";
import * as Application from "expo-application";
import * as appData from "../../app.json";
import { useTailwind } from "tailwind-rn/dist";
import { AuthContext } from "../shared/contexts/AuthContext";
import Sidebar from "../components/organisms/Sidebar";
import { eventsEndpoint } from "../shared/services/api/eventsEndpoint";

import { isTabletActiveEndpoint } from "../shared/services/api/isActiveEndpoint";
import { ConfigContext } from "../shared/contexts/ConfigContext";
import { updateTabletInfo } from "../shared/services/api/updateTabletInfoEndpoint";
import { versionControlEndpoint } from "../shared/services/api/versionControlEndpoint";
import { AuthContextState } from "../shared/models/State";
import { getValueFor } from "../shared/services/storage";
import { Endpoints } from "../shared/models/Endpoints";
import { runLEDswitcher } from "../shared/utilities/LEDcontrols";
import { InfoPopup } from "../components/molecules/InfoPopup";
import { QuickEventActions } from "../components/organisms/QuickEventActions";
import { getRemainingTimeText } from "../shared/utilities/getRemainingTimeText";
import { parseEventTime } from "../shared/utilities/parseEventTime";
import { UpcomingEvent } from "../components/molecules/UpcomingEvent";
import { CurrentEvent } from "../components/molecules/CurrentEvent";
import { CurrentEventActions } from "../components/organisms/CurrentEventActions";
import { SpaceStatus } from "../components/atoms/SpaceStatus";
import { SpaceTitle } from "../components/atoms/SpaceTitle";
import * as Sentry from "sentry-expo";

let updateEventsGlobal;

const Home = ({ navigation }: any) => {
  let eventsInterval;
  let isActiveInterval;
  let versionControlInterval;
  let updateTabletInfoInterval;

  const tailwind = useTailwind();
  const { state, stateActions } = useContext(AuthContext);
  const { configState } = useContext(ConfigContext);

  const [events, setEvents] = useState([]);
  const [spaceStatus, setSpaceStatus] = useState({
    status: "",
    text: "",
  });
  const [currentEvent, setCurrentEvent] = useState(null);
  const [upcomingEvent, setUpcomingEvent] = useState();
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [showPermanentInfoPopup, setShowPermanentInfoPopup] = useState(false);
  const [infoPopupData, setInfoPopupData] = useState(null);

  useEffect(() => {
    (async () => {
      await updateEvents(state);
    })();
    eventsInterval = setInterval(() => {
      updateEvents(state);
    }, 30000);
    isActiveInterval = setInterval(() => {
      if (configState.api_endpoints) {
        isTabletActiveEndpoint(
          state.deviceConfig.tabletId,
          configState.api_endpoints
        )
          .then((response) => {
            const tablet_status = response.data;
            if (
              !tablet_status.status &&
              tablet_status.action === "deactivate"
            ) {
              Platform.select({
                web: () => {
                  Sentry.Browser.captureException(response);
                },
                android: () => {
                  Sentry.Native.captureException(response);
                },
              });
              stateActions.deactivate();
            } else if (
              !tablet_status.status &&
              tablet_status.action === "block"
            ) {
              setShowPermanentInfoPopup(true);
              setInfoPopupData({
                size: "fullscreen",
                type: "error",
                message:
                  "Your tablet is currently not active. Please check your Spaceti subscription limitions.",
              });
            } else if (tablet_status.status) {
              setShowPermanentInfoPopup(false);
            }
          })
          .catch((error) => console.log(error));
      }
    }, 60 * 60 * 1000);

    updateTabletInfoInterval = setInterval(() => {}, 1000 * 60 * 60);

    versionControlInterval = setInterval(() => {
      let running_version = "";
      if (Platform.OS === "android") {
        running_version = Application.nativeApplicationVersion;
        // console.log(Application.nativeApplicationVersion);
      } else if (Platform.OS === "web") {
        running_version = appData.expo.version;
        // console.log(appData.expo.version);
      }
      if (configState.api_endpoints) {
        updateTabletInfo(
          state.deviceConfig.tabletId,
          configState.api_endpoints,
          running_version
        ).catch((error) => console.log(error));
        versionControlEndpoint(
          configState.api_endpoints,
          Platform.OS,
          running_version
        ).catch((error) => console.log(error));
      }
    }, 60 * 60 * 1000);

    runLEDswitcher("on");

    return () => {
      clearInterval(eventsInterval);
      clearInterval(isActiveInterval);
      clearInterval(versionControlInterval);
      runLEDswitcher("off");
    };
  }, []);

  useEffect(() => {
    if (spaceStatus.status) runLEDswitcher(spaceStatus.status);
  }, [spaceStatus]);

  useEffect(() => {
    if (showInfoPopup)
      setTimeout(() => {
        setShowInfoPopup(false);
        setInfoPopupData(null);
      }, 3000);
  }, [showInfoPopup]);

  useEffect(() => {
    if (configState.api_endpoints) {
      (async () => {
        await updateEvents(state);
      })();
    }
  }, [configState.api_endpoints]);

  const updateEvents = async (state: AuthContextState) => {
    const api_endpoints: Endpoints = await getValueFor("api_endpoints");
    if (api_endpoints) {
      await eventsEndpoint(
        state.deviceConfig.tenant,
        state.deviceConfig.space.id,
        api_endpoints
      )
        .then((results) => {
          if (results.data) {
            const events = results.data;
            // console.log(events);
            let upcomingEvent;
            let currentEvent;
            const now = new Date();

            events?.map((event, i) => {
              const start_at = new Date(event.start_at);
              const end_at = new Date(event.end_at);

              const check_in_start_at = new Date(event.check_in.start_at);
              const check_in_end_at = new Date(event.check_in.end_at);

              let can_checkin = false;
              let can_checkout = false;

              event.is_meeting_time = now >= start_at && now <= end_at;
              event.is_checkin_time =
                now >= check_in_start_at && now <= check_in_end_at;
              event.is_check_in_expired =
                !event.check_in.done && now > check_in_end_at;
              event.is_quick_meeting =
                !event.check_in.start_at && event.check_in.end_at;

              // Check if the current event is checked in or not
              if (
                !event.check_in.done &&
                check_in_end_at >= now &&
                check_in_start_at <= now
              ) {
                can_checkin = true;
                event.can_checkin = true;
              } else if (event.check_in.done) {
                can_checkout = true;
                event.can_checkout = true;
              }

              // Case 1. It's meeting time. Check in is done. The meeting is not about to end
              const case1 =
                now >= start_at && now <= end_at && event.check_in.done;

              // Case 9. It's upcoming event.
              const case9 = start_at > now && !upcomingEvent;

              // Case 10. Event is expired
              const case10 = end_at < now;

              // Case 11. Event is in future
              const case11 = start_at > now && upcomingEvent;

              // Case 2. It's not yet meeting time. It's already checkin time. Checkin is not yet done. No current event present.
              const case2 =
                !currentEvent && !event.check_in.done && event.is_checkin_time;

              // Case 3. It's not yet meeting time. It's already checkin time. Checkin is not yet done. No current event present.
              const case3 =
                !currentEvent &&
                !event.is_meeting_time &&
                event.is_checkin_time &&
                end_at > now &&
                event.check_in.done;

              // Case 5. It's not quick meeting. It's meeting time. It's not checkin time anymore. Check in is done.

              if (case1) {
                // console.log("Case 1", case1);
                currentEvent = event;
                setCurrentEvent({
                  id: event.id,
                  title: event.title || "Quick meeting",
                  start_at: event.start_at,
                  end_at: event.end_at,
                  host_name: event.user
                    ? `${event.user.first_name} ${event.user.last_name}`
                    : "",
                  attendies_amount: 0,
                  can_checkin: can_checkin,
                  can_checkout: can_checkout,
                  // host_photo:
                  // "https://elireview.com/wp-content/uploads/2016/12/reed-profile-square.jpg",
                });

                event.status = "busy";

                const [remainingEventTimeText] = getRemainingTimeText(end_at);
                const remainingTimeConnectorText =
                  remainingEventTimeText.length > 0 ? "in" : "soon";

                setSpaceStatus({
                  status: "busy",
                  text: `Meeting ends ${remainingTimeConnectorText}${remainingEventTimeText}`,
                });
              } else if (case2) {
                // console.log("Case 2", case2);
                currentEvent = event;
                setCurrentEvent({
                  id: event.id,
                  title: event.title || "Quick meeting",
                  start_at: event.start_at,
                  end_at: event.end_at,
                  host_name: event.user
                    ? `${event.user.first_name} ${event.user.last_name}`
                    : "",
                  attendies_amount: 0,
                  can_checkin: can_checkin,
                  can_checkout: can_checkout,
                  // host_photo:
                  // "https://elireview.com/wp-content/uploads/2016/12/reed-profile-square.jpg",
                });
                const [remainingCheckinTimeText] =
                  getRemainingTimeText(check_in_end_at);
                const remainingCheckinTimeConnectorText =
                  remainingCheckinTimeText.length > 0 ? "in" : "soon";

                event.status = "checkin";

                setSpaceStatus({
                  status: "checkin",
                  text: `Check-in ends ${remainingCheckinTimeConnectorText}${remainingCheckinTimeText}`,
                });
              } else if (case3) {
                // console.log("Case 3", case3);
                currentEvent = event;
                setCurrentEvent({
                  id: event.id,
                  title: event.title || "Quick meeting",
                  start_at: event.start_at,
                  end_at: event.end_at,
                  host_name: event.user
                    ? `${event.user.first_name} ${event.user.last_name}`
                    : "",
                  attendies_amount: 0,
                  can_checkin: can_checkin,
                  can_checkout: can_checkout,
                  is_about_to_start: true,
                  // host_photo:
                  // "https://elireview.com/wp-content/uploads/2016/12/reed-profile-square.jpg",
                });
                console.log("Is about to start!");
                const [remainingTimeBeforeMeetingStartText] =
                  getRemainingTimeText(start_at);
                const remainingTimeBeforeMeetingStartConnectorText =
                  remainingTimeBeforeMeetingStartText.length > 0
                    ? "in"
                    : "soon";

                event.status = "busy";

                setSpaceStatus({
                  status: "busy",
                  text: `Meeting starts ${remainingTimeBeforeMeetingStartConnectorText}${remainingTimeBeforeMeetingStartText}`,
                });
              } else if (case9) {
                upcomingEvent = event;
                if (event.can_checkin) event.status = "checkin";
                else event.status = "busy";
                setUpcomingEvent(event);
              } else if (case10) {
                event.status = "expired";
              } else if (case11) {
                event.status = "busy";
              }

              // console.log(event);

              if (!event.status) event.status = "expired";
              return event;
            });

            let someMeetingIsAboutToStart = false;

            events?.map((event) => {
              if (event.is_about_to_start) someMeetingIsAboutToStart = true;
            });

            if (!currentEvent && !upcomingEvent && !someMeetingIsAboutToStart) {
              setSpaceStatus({
                status: "available",
                text: `Available`,
              });
              setCurrentEvent(undefined);
            } else if (
              !currentEvent &&
              upcomingEvent &&
              !someMeetingIsAboutToStart
            ) {
              const upcoming_event_start_at = new Date(upcomingEvent.start_at);
              const [remainingAvailableTimeText] = getRemainingTimeText(
                upcoming_event_start_at
              );
              setSpaceStatus({
                status: "available",
                text: `Available for${remainingAvailableTimeText}`,
              });
              setCurrentEvent(undefined);
            }
            if (!upcomingEvent) setUpcomingEvent(undefined);
            setEvents(events);
          }
        })
        .catch((error) => {
          console.log(error);
          // TODO: Refactor to deactivate in a more decentralised way
          if (
            error?.response?.status === 401 ||
            error?.response?.status === 403
          ) {
            Platform.select({
              web: () => {
                Sentry.Browser.captureException(error);
              },
              android: () => {
                Sentry.Native.captureException(error);
              },
            });
            stateActions.deactivate();
          }
        });
    } else {
      console.log("No api endpoints id");
    }
  };
  updateEventsGlobal = updateEvents;

  const eventHandler = (status, message) => {
    setInfoPopupData({
      type: status,
      message: message,
    });
    setShowInfoPopup(true);
  };

  return (
    <SafeAreaView style={tailwind("h-full bg-black")}>
      <View style={tailwind("h-full w-full bg-black flex flex-row")}>
        <View style={tailwind("w-2/3 h-full flex flex-col content-between")}>
          <SpaceTitle></SpaceTitle>
          {spaceStatus && (
            <SpaceStatus
              status={spaceStatus.status || "checkin"}
              text={spaceStatus.text}
            ></SpaceStatus>
          )}
          {currentEvent && <CurrentEvent event={currentEvent}></CurrentEvent>}
          {currentEvent ? (
            <CurrentEventActions
              event={currentEvent}
              upcomingEvent={upcomingEvent}
              style="absolute bottom-[150px] left-0"
              eventHandler={eventHandler}
              updateEventsGlobal={updateEventsGlobal}
            ></CurrentEventActions>
          ) : spaceStatus ? (
            <QuickEventActions
              upcomingEvent={upcomingEvent}
              eventHandler={eventHandler}
              updateEventsGlobal={updateEventsGlobal}
            ></QuickEventActions>
          ) : (
            <></>
          )}
          {upcomingEvent && (
            <UpcomingEvent
              style={"absolute bottom-0 left-0"}
              event={upcomingEvent}
              eventHandler={eventHandler}
              updateEventsGlobal={updateEventsGlobal}
            ></UpcomingEvent>
          )}
        </View>
        <View
          style={tailwind("w-1/3 rounded-l-3xl overflow-hidden bg-checkin/20")}
        >
          <Sidebar
            events={events?.map((event) => {
              return {
                start: parseEventTime(event.start_at),
                end: event.is_check_in_expired
                  ? parseEventTime(event.check_in.end_at)
                  : parseEventTime(event.end_at),
                title: event.title || "Quick meeting",
                host: event.user
                  ? `by ${event.user.first_name} ${event.user.last_name}`
                  : "",
                status: event.status,
              };
            })}
          ></Sidebar>
        </View>
      </View>
      {(showInfoPopup || showPermanentInfoPopup) && (
        <InfoPopup data={infoPopupData}></InfoPopup>
      )}
    </SafeAreaView>
  );
};

export default Home;
