import * as React from "react";
import Svg, { Path } from "react-native-svg";

function CheckboxThin(props) {
  return (
    <Svg
      width={46}
      height={46}
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 .25C10.435.25.25 10.435.25 23S10.435 45.75 23 45.75 45.75 35.565 45.75 23 35.565.25 23 .25zm0 1.5c11.736 0 21.25 9.514 21.25 21.25S34.736 44.25 23 44.25 1.75 34.736 1.75 23 11.264 1.75 23 1.75zm12.53 12.72a.75.75 0 00-1.06 0L19 29.939l-7.47-7.47-.084-.072a.75.75 0 00-.976 1.133l8 8 .084.073a.75.75 0 00.976-.073l16-16 .073-.084a.75.75 0 00-.073-.976z"
        fill="#6DD400"
      />
    </Svg>
  );
}

export default CheckboxThin;
