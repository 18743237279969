import React, {
  createContext,
  useReducer,
  useMemo,
  useContext,
  useState,
  useEffect,
} from "react";
import { save, remove, getValueFor } from "../services/storage";
import { AuthContextData, AuthContextState } from "../models/State";
import { DeviceConfig } from "../models/DeviceConfig";

export const AuthContext = createContext({} as AuthContextData);

// TODO: Rename to DeviceContext / DeviceProvider
export const AuthProvider = ({ children }: any) => {
  let initialState = {
    userToken: null,
    stateLoading: true,
    deviceConfig: null,
  };
  const [state, setState] = useState(initialState);

  useEffect(() => {
    var tokenPromise = new Promise((resolve, reject) => {
      getValueFor("token").then((token) => {
        resolve(token);
      });
    });
    var deviceConfigPromise = new Promise((resolve, reject) => {
      getValueFor("device_config").then((device_config) => {
        resolve(device_config);
      });
    });

    Promise.all([tokenPromise, deviceConfigPromise]).then((data) => {
      const token: string = data[0] as string;
      const deviceConfig: DeviceConfig = data[1] as DeviceConfig;
      setState({
        ...state,
        userToken: token,
        deviceConfig: deviceConfig,
        stateLoading: false,
      });
    });
  }, []);

  useEffect(() => {
    console.log(state);
  }, [state]);

  const authContext = {
    activateTablet: async (
      token: string,
      refreshToken: string,
      deviceConfig: DeviceConfig
    ) => {
      await save("refresh_token", refreshToken, false);
      await save("token", token, false);
      await save("device_config", deviceConfig, true);
      setState({
        ...state,
        userToken: token,
        deviceConfig: deviceConfig,
        stateLoading: false,
      });
    },
    deactivate: async () => {
      await remove("refresh_token");
      await remove("token");
      await remove("device_config");
      setState({
        ...state,
        userToken: null,
        deviceConfig: null,
      });
    },
  };

  return (
    <AuthContext.Provider
      value={{
        state: state,
        stateActions: authContext,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
}
