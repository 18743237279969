import { Platform } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";

export async function save(key: string, value: any, object: boolean) {
  let saveValue = "";
  if (object) {
    saveValue = `object:${JSON.stringify(value)}`;
  } else {
    saveValue = value;
  }
  await AsyncStorage.setItem(key, saveValue);
}

export async function remove(key: string) {
  await AsyncStorage.removeItem(key);
}

export async function getValueFor(key: string) {
  let value = await AsyncStorage.getItem(key).then((data) => data);
  if (value?.indexOf("object:") === 0) {
    value = value.replace("object:", "");
    return JSON.parse(value);
  } else {
    return value;
  }
}
