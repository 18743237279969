import React, { createContext, useContext, useState, useEffect } from "react";
import {
  UtilityContextData,
  UtilityContextState,
} from "../models/UtilityContext";
import { save, remove, getValueFor } from "../utilities/storage";

export const UtilityContext = createContext({} as UtilityContextData);

export const UtilityProvider = ({ children, apiEndpoints, auth0ClientId }) => {
  let initialState: UtilityContextState = {
    apiEndpoints: apiEndpoints,
    auth0ClientId: auth0ClientId,
  };
  const [utilityState, setUtilityState] = useState(initialState);

  useEffect(() => {
    setUtilityState({ ...utilityState, apiEndpoints: apiEndpoints });
  }, [apiEndpoints]);

  const utilityStateActions = {
    toggleCancelSetupConfirm: (show: boolean) =>
      setUtilityState({
        ...utilityState,
        showCancelSetupConfirm: show,
      }),
  };

  useEffect(() => {
    save("activation_utility", utilityState, true);
  }, [utilityState]);

  return (
    <UtilityContext.Provider
      value={{
        utilityState: utilityState,
        utilityStateActions: utilityStateActions,
      }}
    >
      {children}
    </UtilityContext.Provider>
  );
};

export function useUtility() {
  const context = useContext(UtilityContext);

  if (!context) {
    throw new Error("useUtility must be used within an UtilityProvider");
  }

  return context;
}
