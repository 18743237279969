import api from "./api";
import { Tenant } from "../models";
import { Endpoints } from "../models/Endpoints";

export async function getListOfBuildings(
  endpoints: Endpoints,
  userToken: string,
  tenant: Tenant
) {
  let listOfBuildings: any = [];
  await api
    .get(
      endpoints.tablet_endpoints.buildings.replace(
        "[tenant_subdomain]",
        tenant.subdomain
      )
    )
    .then((results) => {
      listOfBuildings = results.data;
    })
    .catch((error) => console.log(error));
  return listOfBuildings;
}
