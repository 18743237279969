import React, { useContext } from "react";
import { Text, View, Image, TouchableOpacity, Platform } from "react-native";
import { useTailwind } from "tailwind-rn";
import Logo from "../../assets/img/Logo";
import ArrowBack from "../../assets/img/ArrowBack";
import ArrowNext from "../../assets/img/ArrowNext";
import { ActivationContext } from "../../shared/contexts/ActivationContext";

const ActivationHeader = (props: any) => {
  const tailwind = useTailwind();
  const { activationState, activationStateActions } =
    useContext(ActivationContext);

  const stepBack = () => {
    switch (props.slug) {
      case "tenant":
        console.log(activationState, "in header");
        activationStateActions({
          type: "UPDATE_ACTIVATION_STATE",
          payload: {
            token: null,
            refresh_token: null,
          },
        });
        props.navigation.goBack();
        break;
      case "building":
        activationStateActions({
          type: "UPDATE_ACTIVATION_STATE",
          payload: {
            tenant: null,
          },
        });
        props.navigation.goBack();
        break;
      case "floor":
        activationStateActions({
          type: "UPDATE_ACTIVATION_STATE",
          payload: {
            building: null,
          },
        });
        props.navigation.goBack();
        break;
      case "space":
        console.log("by space");
        activationStateActions({
          type: "UPDATE_ACTIVATION_STATE",
          payload: {
            floor: null,
          },
        });
        props.navigation.goBack();
        break;
      case "finish":
        activationStateActions({
          type: "UPDATE_ACTIVATION_STATE",
          payload: {
            space: null,
          },
        });
        props.navigation.goBack();
        break;
      default:
        break;
    }
  };
  // ios text-center text-5xl font-inter font-semibold text-neutral-black pt-16
  return (
    <View style={tailwind("flex h-20 pt-5")}>
      <Text
        style={{
          ...tailwind("text-center font-inter text-neutral-black"),
          ...Platform.select({
            ios: tailwind("text-5xl pt-16 font-semibold"),
            android: tailwind("text-4xl pt-3 font-bold"),
            web: tailwind("text-4xl pt-3 font-bold"),
          }),
        }}
      >
        {props.text}
      </Text>

      <View style={tailwind("flex flex-row justify-center items-center")}>
        {activationState?.tenant && (
          <Text
            style={{
              ...tailwind("text-center text-lg font-inter text-gray pt-2"),
              ...Platform.select({
                ios: tailwind("font-semibold"),
                android: tailwind("font-bold"),
                web: tailwind("font-bold"),
              }),
            }}
          >
            {activationState?.tenant?.name}
          </Text>
        )}

        {activationState?.building && (
          <>
            <ArrowNext style={tailwind("ml-2 mr-1 mt-2")}></ArrowNext>
            <Text
              style={{
                ...tailwind("text-center text-lg font-inter text-gray pt-2"),
                ...Platform.select({
                  ios: tailwind("font-semibold"),
                  android: tailwind("font-bold"),
                  web: tailwind("font-bold"),
                }),
              }}
            >
              {activationState?.building?.label}
            </Text>
          </>
        )}

        {activationState?.floor && (
          <>
            <ArrowNext style={tailwind("ml-2 mr-1 mt-2")}></ArrowNext>
            <Text
              style={{
                ...tailwind("text-center text-lg font-inter text-gray pt-2"),
                ...Platform.select({
                  ios: tailwind("font-semibold"),
                  android: tailwind("font-bold"),
                  web: tailwind("font-bold"),
                }),
              }}
            >
              {activationState?.floor?.title}
            </Text>
          </>
        )}

        {activationState?.space && (
          <>
            <ArrowNext style={tailwind("ml-2 mr-1 mt-2")}></ArrowNext>
            <Text
              style={{
                ...tailwind("text-center text-lg font-inter text-gray pt-2"),
                ...Platform.select({
                  ios: tailwind("font-semibold"),
                  android: tailwind("font-bold"),
                  web: tailwind("font-bold"),
                }),
              }}
            >
              {activationState?.space?.title}
            </Text>
          </>
        )}
      </View>

      {props.slug !== "token" ? (
        <TouchableOpacity
          onPress={() => stepBack()}
          style={tailwind(`absolute top-7 left-16 m-5`)}
        >
          <ArrowBack></ArrowBack>
        </TouchableOpacity>
      ) : (
        <Logo style={tailwind("absolute top-5 left-3 m-5")}></Logo>
      )}
    </View>
  );
};

export default ActivationHeader;
