import api from "./api";

import { Endpoints } from "../../models/Endpoints";

export async function quickEventEndpoint(
  tenant: any,
  space_id: string,
  duration: number,
  endpoints: Endpoints
) {
  return api.post(
    endpoints.meeting_room_app_endpoints.quick_event.replace(
      "[tenant_subdomain]",
      tenant.subdomain
    ),
    {
      space_id: space_id,
      duration: duration,
    }
  );
}
