import { useTailwind } from "tailwind-rn/dist";
import { Platform, Text, Image, View } from "react-native";

export const CurrentEvent = ({ event }) => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind("p-8 mt-10 flex flex-col")}>
      <Text
        style={{
          ...tailwind("text-white font-inter text-2xl"),
          ...Platform.select({
            web: tailwind("font-extrabold"),
            android: tailwind("font-bold"),
          }),
        }}
      >
        {event.title}
      </Text>
      <View
        style={{
          ...tailwind("flex flex-row mt-4"),
          ...Platform.select({
            web: tailwind("mt-2"),
            android: tailwind("mt-4"),
          }),
        }}
      >
        <View>
          {!event.host_photo && event.host_name ? (
            <Text
              style={{
                ...tailwind(
                  "text-white bg-avatar-back-1 rounded-3xl w-[40px] h-[40px] pt-1 text-center font-bold text-lg"
                ),
                ...Platform.select({
                  web: tailwind("font-extrabold pt-[5px]"),
                  android: tailwind("font-bold pt-1"),
                }),
              }}
            >
              {event?.host_name?.split(" ")[0][0]}
              {event?.host_name?.split(" ")[1][0]}
            </Text>
          ) : (
            <Image
              style={tailwind("w-[40px] h-[40px] rounded-3xl")}
              source={{
                uri: event.host_photo,
              }}
            />
          )}
        </View>
        <View style={tailwind("flex flex-row")}>
          <Text
            style={{
              ...tailwind("text-white font-inter  font-semibold pt-1 pl-4"),
              ...{
                color: "#999999",
              },
              ...Platform.select({
                web: tailwind("font-extrabold pt-[6px] text-xl"),
                android: tailwind("font-semibold text-lg"),
              }),
            }}
          >
            {event.host_name}
          </Text>
          {event.attendies_amount > 0 && (
            <Text
              style={{
                ...tailwind("text-white text-lg pt-1 pl-1"),
                ...{
                  color: "#999999",
                },
              }}
            >
              +{event.attendies_amount}
            </Text>
          )}
        </View>
      </View>
    </View>
  );
};
