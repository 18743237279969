import React, {
  createContext,
  useReducer,
  useMemo,
  useContext,
  useState,
  useEffect,
} from "react";
import { save, remove, getValueFor } from "../utilities/storage";
import {
  ActivationContextData,
  ActivationContextState,
} from "../models/ActivationContext";
import { getDeviceInfo } from "../utilities/getDeviceInfo";

export const ActivationContext = createContext({} as ActivationContextData);

export const ActivationProvider = ({
  children,
  deviceEntityLevel,
  setDeviceConfig,
}) => {
  let initialState: ActivationContextState = {
    deviceEntityLevel: deviceEntityLevel,
    stateLoading: true,
    token: null,
    tenant: null,
    building: null,
    floor: null,
    space: null,
    deviceInfo: null,
    tabletId: null,
    refresh_token: null,
  };

  const activationReducer = (state, action) => {
    switch (action.type) {
      case "UPDATE_ACTIVATION_STATE":
        console.log("updating activation state", action.payload);
        return {
          ...state,
          ...action.payload,
        };
      case "RESET_ACTIVATION_STATE":
        console.log("resetting activation state");
        remove("activation_config");
        return {
          ...initialState,
          stateLoading: false,
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(activationReducer, initialState);

  useEffect(() => {
    (async () => {
      const activationConfig = await getValueFor("activation_config");
      console.log("finifshed reading from storage");

      dispatch({
        type: "UPDATE_ACTIVATION_STATE",
        payload: {
          ...activationConfig,
          stateLoading: false,
        },
      });

      const deviceInfo = await getDeviceInfo();
      dispatch({
        type: "UPDATE_ACTIVATION_STATE",
        payload: { deviceInfo },
      });
    })();
  }, []);

  useEffect(() => {
    save("activation_config", state, true);
  }, [state]);

  useEffect(() => {
    if (state.tabletId) {
      console.log("tabletId present");
      setDeviceConfig(
        {
          tenant: state.tenant,
          building: state.building,
          floor: state.floor,
          space: state.space,
          deviceInfo: state.deviceInfo,
          tabletId: state.tabletId,
          deviceEntityLevel: state.deviceEntityLevel,
        },
        state.token,
        state.refresh_token
      );
      remove("activation_config");
      remove("activation_utility");
    }
  }, [state.tabletId]);

  return (
    <ActivationContext.Provider
      value={{
        activationState: state,
        activationStateActions: dispatch,
      }}
    >
      {children}
    </ActivationContext.Provider>
  );
};

export function useActivation() {
  const context = useContext(ActivationContext);

  if (!context) {
    throw new Error("useActivation must be used within an ActivationProvider");
  }

  return context;
}
