import axios from "axios";
import { ActivationContextState } from "../../../ActivationModule/shared/models/ActivationContext";
import { getValueFor, save } from "../../utilities/storage";
import { refreshToken } from "./auth0Endpoints";

const instance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  async (config) => {
    const token: string = await getValueFor("token");

    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const config = err.config;
    if (config.url.indexOf("/proxy/") < 0 && err.response) {
      if (err.response.status === 401 && !config._retry) {
        config._retry = true;
        try {
          const refresh_token = await getValueFor("refresh_token");
          const auth0ClientId = await getValueFor("auth0ClientId");
          console.log("refresh_token", refresh_token);
          console.log("auth0ClientId", auth0ClientId);
          const rs = await refreshToken(auth0ClientId, refresh_token);
          const { access_token } = rs.data;
          await save("token", access_token, false);
          config.headers["Authorization"] = `Bearer ${access_token}`;
          return instance(config);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);

export default instance;
