// Libraries
import React, { useEffect, useState } from "react";
import {
  SafeAreaView,
  Text,
  View,
  Platform,
  TouchableOpacity,
  PermissionsAndroid,
} from "react-native";
import QRCode from "react-native-qrcode-svg";
import { useTailwind } from "tailwind-rn";

// Logic-related
import DeviceCodeModel from "../shared/models/DeviceCode";
import { requestToken, requestDeviceCode } from "../shared/services";
import { useActivation } from "../shared/contexts/ActivationContext";
import { useUtility } from "../shared/contexts/UtilityContext";

// UI-related
import { ActivationHeader } from "../components/organisms";
import { Circle } from "react-native-animated-spinkit";
import Attention from "../assets/img/Attention";
import Refresh from "../assets/img/Refresh";

const Authentification = ({ navigation }: any) => {
  const tailwind = useTailwind();
  const { activationState, activationStateActions } = useActivation();
  const { utilityState } = useUtility();
  let deviceCodeAuthorizationTimeout;

  const [deviceCode, setDeviceCode] = useState<DeviceCodeModel>({});
  const [storagePermissionsAllowed, setStoragePermissionsAllowed] =
    useState(true);

  const initiateAuthProcess = () => {
    if (utilityState?.apiEndpoints && !activationState.stateLoading) {
      clearTimeout(deviceCodeAuthorizationTimeout);
      requestDeviceCode(
        utilityState?.auth0ClientId,
        utilityState.apiEndpoints
      ).then((deviceCode) => {
        setDeviceCode(deviceCode);
      });
    }
  };

  useEffect(() => {
    const unsubscribeFocus = navigation.addListener("focus", () => {
      initiateAuthProcess();
    });
    return unsubscribeFocus;
  }, [navigation, activationState]);

  useEffect(() => {
    initiateAuthProcess();
  }, [activationState.stateLoading]);

  useEffect(() => {
    if (activationState?.token) {
      navigation.navigate("TenantsScreen");
    }
  }, [activationState.token]);

  useEffect(() => {
    if (deviceCode.device_code && !activationState?.token)
      waitForDeviceCodeAuthorization();
  }, [deviceCode]);

  useEffect(() => {
    (async () => {
      if (Platform.OS === "android") {
        const write_external_storage = await PermissionsAndroid.check(
          PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE
        );
        const read_external_storage = await PermissionsAndroid.check(
          PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE
        );
        if (write_external_storage && read_external_storage)
          setStoragePermissionsAllowed(true);
        else setStoragePermissionsAllowed(false);
      }
    })();
  }, []);

  function requestStoragePermissions() {
    PermissionsAndroid.requestMultiple([
      PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
      PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE,
    ]).then((value) => {
      if (
        value["android.permission.READ_EXTERNAL_STORAGE"] === "granted" &&
        value["android.permission.WRITE_EXTERNAL_STORAGE"] === "granted"
      )
        setStoragePermissionsAllowed(true);
    });
  }

  function waitForDeviceCodeAuthorization() {
    requestToken(
      deviceCode.device_code,
      utilityState.auth0ClientId,
      utilityState.apiEndpoints
    )
      .then((token) => {
        if (token.access_token)
          activationStateActions({
            type: "UPDATE_ACTIVATION_STATE",
            payload: {
              token: token.access_token,
              refresh_token: token.refresh_token,
            },
          });
      })
      .catch((error) => {
        switch (error.response.data.error) {
          case "authorization_pending":
            deviceCodeAuthorizationTimeout = setTimeout(
              () => waitForDeviceCodeAuthorization(),
              (deviceCode.interval! + 1) * 1000
            );
            break;
          case "invalid_grant":
            requestDeviceCode(
              utilityState.auth0ClientId,
              utilityState.apiEndpoints
            ).then((deviceCode: DeviceCodeModel) => {
              setDeviceCode(deviceCode);
            });
            break;
          case "expired_token":
            requestDeviceCode(
              utilityState.auth0ClientId,
              utilityState.apiEndpoints
            ).then((deviceCode: DeviceCodeModel) => {
              setDeviceCode(deviceCode);
            });
          default:
            console.log();
            break;
        }
      });
  }

  return (
    <SafeAreaView style={tailwind("h-full bg-neutral")}>
      <ActivationHeader
        navigation={navigation}
        slug="token"
        text="Two Ways To Activate"
      ></ActivationHeader>

      <View style={tailwind(`flex flex-row w-full mt-20`)}>
        <LeftColumn
          refreshCode={initiateAuthProcess}
          deviceCode={deviceCode}
        ></LeftColumn>
        <ColumnsDivider></ColumnsDivider>
        <RightColumn deviceCode={deviceCode}></RightColumn>
      </View>

      <BottomNotice></BottomNotice>

      {!storagePermissionsAllowed && (
        <StoragePermissionsModal
          requestStoragePermissions={requestStoragePermissions}
        ></StoragePermissionsModal>
      )}
    </SafeAreaView>
  );
};

export default Authentification;

const LeftColumn = ({ deviceCode, refreshCode }: any) => {
  const tailwind = useTailwind();

  return (
    <View style={tailwind("grow w-5/12 items-center")}>
      <Text
        style={{
          ...tailwind("font-inter text-3xl text-neutral-black "),
          ...Platform.select({
            ios: tailwind("font-semibold"),
            android: tailwind("font-bold"),
            web: tailwind("font-bold"),
          }),
        }}
      >
        Scan QR Code
      </Text>

      {deviceCode?.verification_uri_complete?.length > 0 ? (
        <View style={tailwind("bg-white p-3 border-8 rounded-lg mt-10")}>
          <QRCode
            // logo={{ uri: logoBWbase64 }}
            value={deviceCode.verification_uri_complete}
            size={180}
            logoSize={40}
            logoMargin={5}
            logoBackgroundColor="white"
            logoBorderRadius={7}
          />
        </View>
      ) : (
        <View style={tailwind("items-center pt-24 pb-32")}>
          <Circle size={48} color="#8891ab"></Circle>
        </View>
      )}

      <View style={tailwind("flex w-[200px] justify-center")}>
        <TouchableOpacity
          onPress={() => {
            refreshCode();
          }}
          style={tailwind("flex flex-row justify-center pt-3")}
        >
          <Text style={tailwind("font-inter text-lg pr-3")}>Refresh</Text>
          <Refresh style={tailwind("w-[20px]")}></Refresh>
        </TouchableOpacity>
      </View>
      <Text
        style={tailwind(
          "text-neutral-black text-xl text-center leading-5 font-inter font-semibold leading-6	pt-10"
        )}
      >
        Use your camera app or a QR{"\n"} code reader on your device to
        {"\n"} scan the code
      </Text>
    </View>
  );
};

const ColumnsDivider = () => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind("grow-0 w-1/12 h-full items-center flex flex-col ")}>
      <View style={tailwind("h-2/6 w-[1px] bg-neutral-lighter")}></View>
      <Text
        style={{
          ...tailwind("h-1/6 font-inter text-3xl text-neutral-black pt-7"),
          ...Platform.select({
            ios: tailwind("font-semibold"),
            android: tailwind("font-bold"),
            web: tailwind("font-bold"),
          }),
        }}
      >
        OR
      </Text>
      <View style={tailwind("h-2/6 w-[1px] bg-neutral-lighter")}></View>
    </View>
  );
};

const RightColumn = ({ deviceCode }: any) => {
  const tailwind = useTailwind();

  return (
    <View style={tailwind("grow w-5/12 items-center px-10")}>
      <Text
        style={{
          ...tailwind("font-inter text-3xl text-neutral-black "),
          ...Platform.select({
            ios: tailwind("font-semibold"),
            android: tailwind("font-bold"),
            web: tailwind("font-bold"),
          }),
        }}
      >
        Visit Website
      </Text>
      <Text
        style={{
          ...tailwind(
            "font-inter text-xl text-neutral-black text-center pt-10"
          ),
          ...Platform.select({
            ios: tailwind("font-semibold"),
            android: tailwind("font-bold"),
            web: tailwind("font-bold"),
          }),
        }}
      >
        From your PC, phone or {"\n"}tablet go to:
      </Text>
      {deviceCode.verification_uri ? (
        <Text
          style={{
            ...tailwind("font-inter text-2xl text-blue text-center"),
            ...Platform.select({
              ios: tailwind("font-semibold"),
              android: tailwind("font-bold"),
              web: tailwind("font-bold"),
            }),
          }}
        >
          {`${deviceCode.verification_uri.replace("https://", "")}`}
        </Text>
      ) : (
        <></>
      )}
      <Text
        style={{
          ...tailwind(
            "font-inter text-xl text-neutral-black text-center pt-14"
          ),
          ...Platform.select({
            ios: tailwind("font-semibold"),
            android: tailwind("font-bold"),
            web: tailwind("font-bold"),
          }),
        }}
      >
        When asked, enter code:
      </Text>
      {deviceCode?.user_code?.length > 0 ? (
        <Text
          style={{
            ...tailwind("font-inter text-blue text-center pt-5"),
            ...Platform.select({
              ios: tailwind(
                "text-5xl font-semibold tracking-widest leading-10"
              ),
              android: tailwind(
                "text-4xl font-bold tracking-widest leading-10"
              ),
              web: tailwind("text-4xl font-bold tracking-widest leading-10"),
            }),
          }}
        >
          {deviceCode.user_code}
        </Text>
      ) : (
        <View style={tailwind("items-center pt-5")}>
          <Circle size={48} color="#8891ab"></Circle>
        </View>
      )}
      <Text></Text>
    </View>
  );
};

const BottomNotice = () => {
  const tailwind = useTailwind();

  return (
    <View
      style={tailwind(
        "absolute w-full bottom-0 items-center justify-center h-20"
      )}
    >
      <Text
        style={{
          ...tailwind("text-gray text-xl leading-5 font-inter font-bold"),
          ...Platform.select({
            ios: tailwind("font-semibold"),
            android: tailwind("font-bold"),
            web: tailwind("font-bold"),
          }),
        }}
      >
        Once done, the screen will automatically update
      </Text>
    </View>
  );
};

const StoragePermissionsModal = ({ requestStoragePermissions }: any) => {
  const tailwind = useTailwind();

  return (
    <View
      style={tailwind(
        "absolute w-full justify-center items-center h-[110%] bg-neutral-black/75"
      )}
    >
      <View style={tailwind("w-[430px] h-[300px]")}>
        <View
          style={tailwind(
            "w-full h-full bg-white rounded-lg justify-center items-center"
          )}
        >
          <Attention></Attention>
          <Text
            style={tailwind(
              "font-inter text-xl font-bold text-neutral-black pt-3"
            )}
          >
            Local storage usage permissions
          </Text>
          <Text
            style={tailwind(
              "font-inter text-sm text-center font-semibold text-gray px-8"
            )}
          >
            We are not going to access any files on your device. We need those
            permissions to store settings of your tablet.
          </Text>
          <View
            style={tailwind("flex flex-row justify-center items-center w-full")}
          >
            <TouchableOpacity
              onPress={() => requestStoragePermissions()}
              style={tailwind(
                `h-11 justify-center rounded-lg mt-8 bg-light-gray mx-2 px-4`
              )}
            >
              <Text
                style={tailwind(
                  `text-white font-inter text-base font-semibold text-neutral-black text-center`
                )}
              >
                OK. Ask me for permissions.
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </View>
  );
};
