import { AppRegistry } from "react-native";
import App from "./src";
import { expo } from "./app.json";
import * as Sentry from "sentry-expo";
import "./input.css";

Sentry.init({
  dsn: "https://8832e75054104302925f40c39982eeed@o247397.ingest.sentry.io/6465896",
  release: "1.0",
});

AppRegistry.registerComponent(expo.name, () => App);
AppRegistry.runApplication(expo.name, {
  // Mount the react-native app in the "root" div of index.html
  rootTag: document.getElementById("root"),
});
