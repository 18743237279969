import api from "./api";
import { Endpoints } from "../models/Endpoints";

export async function getListOfFloors(
  endpoints: Endpoints,
  userToken: string,
  tenant: any,
  building_id: number
) {
  let listOfFloors: any = [];

  await api
    .get(
      endpoints.tablet_endpoints.floors
        .replace("[tenant_subdomain]", tenant.subdomain)
        .replace("[building_id]", `${building_id}`)
    )
    .then((results) => {
      console.log(results.data);
      listOfFloors = results.data;
    })
    .catch((error) => console.log(error));
  return listOfFloors;
}
