import * as React from "react";
import Svg, { Path } from "react-native-svg";

function CancelIcon(props) {
  return (
    <Svg
      width={14}
      height={14}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 5.586L11.626.96a1 1 0 111.414 1.414L8.414 7l4.626 4.626a1 1 0 01-1.414 1.415L7 8.413l-4.626 4.627A1 1 0 01.96 11.625L5.586 7 .96 2.374A1 1 0 112.374.96L7 5.586z"
        fill="#fff"
      />
    </Svg>
  );
}

export default CancelIcon;
