export function pad(value) {
  if (value < 10) {
    return "0" + value;
  } else {
    return value;
  }
}

export function pluralize(val, word, plural = word + "s") {
  const _pluralize = (num, word, plural = word + "s") =>
    [1, -1].includes(Number(num)) ? word : plural;
  if (typeof val === "object")
    return (num, word) => _pluralize(num, word, val[word]);
  return _pluralize(val, word, plural);
}
