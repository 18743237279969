import React, { useContext, useState, useEffect } from "react";
import { Platform, Text, TouchableOpacity, View } from "react-native";
import { Pulse } from "react-native-animated-spinkit";
import { useTailwind } from "tailwind-rn/dist";
import CancelIcon from "../../assets/img/CancelIcon";
import MinusIcon from "../../assets/img/MinusIcon";
import PlusIcon from "../../assets/img/PlusIcon";
import { AuthContext } from "../../shared/contexts/AuthContext";
import { ConfigContext } from "../../shared/contexts/ConfigContext";
import { prolongEventEndpoint } from "../../shared/services/api/prolongEventEndpoint";

export const ExtendEventActions = ({
  currentEvent,
  cancelAction,
  upcomingEvent,
  eventHandler,
  updateEventsGlobal,
}) => {
  const tailwind = useTailwind();
  const { state } = useContext(AuthContext);
  const { configState } = useContext(ConfigContext);

  const smallStep = 15;
  const bigStep = 15;
  const minDurationValue = 5;
  const maxDurationValue = 60 * 5;

  const [durationValue, setDurationValue] = useState(0);
  const [processingAction, setProcessingAction] = useState(false);
  const [remainingAvailableTime, setRemainingAvailableTime] = useState(0);

  useEffect(() => {
    console.log("interval set");
    setTimeout(() => {
      if (!processingAction) cancelAction();
    }, 30 * 1000);
  }, []);

  useEffect(() => {
    const upcoming_event_start_at = new Date(upcomingEvent?.start_at);
    const current_event_end_at = new Date(currentEvent?.end_at);

    console.log(upcoming_event_start_at);
    console.log(current_event_end_at);

    setRemainingAvailableTime(
      Math.abs(Number(upcoming_event_start_at) - Number(current_event_end_at)) /
        1000 /
        60
    ); //in mins
  }, [upcomingEvent]);

  useEffect(() => {
    if (upcomingEvent && remainingAvailableTime > bigStep)
      setDurationValue(bigStep);
    else if (upcomingEvent && remainingAvailableTime > smallStep)
      setDurationValue(smallStep);
    else if (!upcomingEvent) {
      setDurationValue(bigStep);
      setRemainingAvailableTime(maxDurationValue);
    } else {
      setDurationValue(remainingAvailableTime);
    }
    console.log(remainingAvailableTime);
  }, [remainingAvailableTime]);

  const increaseDurationValue = () => {
    if (durationValue + bigStep <= remainingAvailableTime) {
      setDurationValue(durationValue + bigStep);
    } else if (durationValue + smallStep <= remainingAvailableTime) {
      setDurationValue(durationValue + smallStep);
    } else if (remainingAvailableTime > 0) {
      setDurationValue(Math.floor(remainingAvailableTime));
    }
  };

  const decreaseDurationValue = () => {
    if (durationValue % smallStep === 0) {
      console.log("was even increase. decrease by smallStep");
      if (durationValue - smallStep >= minDurationValue) {
        setDurationValue(durationValue - smallStep);
      }
    } else {
      console.log("was odd increase", durationValue % smallStep);

      if (
        durationValue - smallStep - (durationValue % smallStep) >=
        minDurationValue
      ) {
        setDurationValue(
          durationValue - smallStep - (durationValue % smallStep)
        );
      } else if (
        durationValue - (durationValue % smallStep) >=
        minDurationValue
      ) {
        setDurationValue(durationValue - (durationValue % smallStep));
      }
    }
  };

  return (
    <View style={tailwind("flex flex-row h-[67px]")}>
      <TouchableOpacity
        disabled={processingAction && durationValue <= 0}
        style={{
          ...tailwind(
            "bg-available rounded-lg w-[305px] h-[67px] flex flex-row justify-between"
          ),
          ...Platform.select({
            web: tailwind("w-[320px]"),
            android: tailwind("w-[315px]"),
          }),
        }}
        onPress={() => {
          setProcessingAction(true);
          prolongEventEndpoint(
            state?.deviceConfig.tenant,
            currentEvent.id,
            durationValue,
            configState.api_endpoints
          )
            .then(() => {
              updateEventsGlobal(state).then(() => {
                setProcessingAction(false);
                eventHandler("success", "Meeting successfully extended.");
                cancelAction();
              });
            })
            .catch((error) => {
              eventHandler(
                "error",
                "Unfortunately, meeting could not be extended."
              );
              setProcessingAction(false);
              cancelAction();
            });
        }}
      >
        <Text
          style={{
            ...tailwind(
              `text-white pt-5 pl-4 font-inter text-base font-bold ${
                processingAction ? "opacity-0" : ""
              }`
            ),
            ...Platform.select({
              web: tailwind("text-lg"),
              android: tailwind("text-base"),
            }),
          }}
        >
          Extend meeting
        </Text>
        {!processingAction && (
          <Text
            style={{
              ...tailwind(
                "text-white font-inter font-light rounded-lg bg-[#19a054] p-2 m-3"
              ),
              ...Platform.select({
                web: tailwind("text-lg"),
                android: tailwind("text-base"),
              }),
            }}
          >
            + {durationValue} minutes
          </Text>
        )}
        {processingAction && (
          <Pulse
            style={tailwind("absolute left-[130px] top-3 mx-auto w-[50px]")}
            color="#ffffff"
          ></Pulse>
        )}
      </TouchableOpacity>
      <TouchableOpacity
        disabled={processingAction}
        style={tailwind(
          "bg-[#242424] rounded-lg flex flex-col justify-center content-center px-6 ml-2"
        )}
        onPress={() => {
          decreaseDurationValue();
        }}
      >
        <MinusIcon></MinusIcon>
      </TouchableOpacity>
      <TouchableOpacity
        disabled={
          processingAction ||
          durationValue + smallStep > maxDurationValue ||
          durationValue + bigStep > maxDurationValue
        }
        style={tailwind(
          "bg-[#242424] rounded-lg flex flex-col justify-center content-center px-6 ml-2"
        )}
        onPress={() => {
          increaseDurationValue();
        }}
      >
        <PlusIcon></PlusIcon>
      </TouchableOpacity>
      <TouchableOpacity
        disabled={false}
        style={tailwind(
          "bg-[#242424] rounded-lg flex flex-col justify-center content-center px-6 ml-2"
        )}
        onPress={() => {
          cancelAction();
        }}
      >
        <CancelIcon></CancelIcon>
      </TouchableOpacity>
    </View>
  );
};
