import { View } from "react-native";
import { Circle } from "react-native-animated-spinkit";
import { useTailwind } from "tailwind-rn";

export const Loading = () => {
  const tailwind = useTailwind();
  return (
    <View
      style={tailwind(
        "absolute w-full justify-center items-center h-[110%] bg-neutral-black/75"
      )}
    >
      <Circle color="#8891ab"></Circle>
    </View>
  );
};
