import * as React from "react";
import Svg, { Path } from "react-native-svg";

function ErrorThin(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="46px"
      height="46px"
      viewBox="0 0 64 64"
      xmlSpace="preserve"
      enableBackground="new 0 0 64 64"
      {...props}
    >
      <Path
        fill="#E02020"
        d="M32 0C14.327 0 0 14.327 0 32s14.327 32 32 32 32-14.327 32-32S49.673 0 32 0zm0 62.001C15.432 62.001 2 48.568 2 32S15.432 2 32 2c16.568 0 30 13.432 30 30 0 16.568-13.432 30.001-30 30.001z"
      />
      <Path
        fill="#E02020"
        d="M41.191 24.222L39.777 22.808 32 30.586 24.222 22.808 22.808 24.222 30.586 32 22.808 39.777 24.222 41.191 32 33.414 39.777 41.191 41.191 39.777 33.414 32z"
      />
    </Svg>
  );
}

export default ErrorThin;
