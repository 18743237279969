import api from "./api";
import { Endpoints } from "../models/Endpoints";

export async function getListOfSpaces(
  endpoints: Endpoints,
  tenant: any,
  floor_id: number
) {
  let listOfSpaces: any = [];

  await api
    .get(
      endpoints.tablet_endpoints.spaces
        .replace("[tenant_subdomain]", tenant.subdomain)
        .replace("[floor_id]", `${floor_id}`)
    )
    .then((results) => {
      listOfSpaces = results.data;
    })
    .catch((error) => console.log(error));
  return listOfSpaces;
}
