import React, { useContext, useEffect } from "react";
import { StatusBar, Text } from "react-native";
import utilities from "../tailwind.json";

import { AuthProvider } from "./shared/contexts/AuthContext";
import { ConfigProvider } from "./shared/contexts/ConfigContext";
import { TailwindProvider } from "tailwind-rn";

import { Router } from "./Router";
import { useFonts } from "@expo-google-fonts/inter";

const App = () => {
  const [appLoaded] = useFonts({
    Inter: require("./assets/fonts/Inter.ttf"),
    OpenSans: require("./assets/fonts/OpenSans.ttf"),
  });

  useEffect(() => {
    StatusBar.setHidden(true);
  }, []);

  return (
    <AuthProvider>
      <ConfigProvider>
        <TailwindProvider utilities={utilities}>
          <Router></Router>
        </TailwindProvider>
      </ConfigProvider>
    </AuthProvider>
  );
};

export default App;
