import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

function Logo(props: SvgProps) {
  return (
    <Svg width={32} height={32} fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.74 8.726c.554 0 1.075-.135 1.538-.368.273.917.44 1.955.477 3.139.253 7.935-5.757 18.083-13.557 18.53C7.541 30.637.178 18.678.173 11.397.17 4.553 7.94 2.24 16.218 1.98c4.077-.128 7.878.065 10.703 1.371a3.428 3.428 0 002.82 5.374zM14.653 19.77c-.476-2.084-.197-3.78-.197-3.78-.363-.01-1.534-.04-2.572.188-1.443.317-2.76 1.028-2.76 1.028s2.998-6.558 14.53-8.589c-3.518 3.886-5.494 8.314-6.226 15.756 0 0-2.2-2.086-2.775-4.603z"
        fill="#3B4764"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.79 7.337a2.04 2.04 0 100-4.079 2.04 2.04 0 000 4.079zm-6.267 1.318s-.048.01-.139.032c-1.153.281-9.187 2.38-12.552 7.112 2.444-.84 4.36-.334 4.36-.334s-.258 1.852-.004 3.807c.314 2.413 1.924 4.69 2.24 5.1-.01-.007-.019-.015-.03-.025-.414-.373-2.315-2.082-2.876-4.54-.38-1.666-.275-3.212-.217-3.764-.355-.015-1.478-.041-2.552.172-1.207.24-2.63.99-2.63.99s2.869-6.52 14.4-8.55z"
        fill="#27D6E6"
      />
    </Svg>
  );
}

export default Logo;
