import { useTailwind } from "tailwind-rn/dist";
import React, { useContext, useState } from "react";
import { AuthContext } from "../../shared/contexts/AuthContext";
import { ConfigContext } from "../../shared/contexts/ConfigContext";
import { Platform, Text, TouchableOpacity } from "react-native";
import { checkoutEndpoint } from "../../shared/services/api/checkoutEndpoint";
import { Pulse } from "react-native-animated-spinkit";

export const CheckoutButton = ({ event, eventHandler, updateEventsGlobal }) => {
  const tailwind = useTailwind();
  const { state } = useContext(AuthContext);
  const { configState } = useContext(ConfigContext);
  const [processingAction, setProcessingAction] = useState(false);
  return (
    <TouchableOpacity
      disabled={processingAction}
      style={{
        ...tailwind("bg-busy p-4 rounded-lg h-[67px] pl-7 text-center"),
        ...Platform.select({
          web: tailwind("w-[150px] pl-3"),
          android: tailwind("w-[147px] pl-7"),
        }),
      }}
      onPress={() => {
        setProcessingAction(true);
        checkoutEndpoint(
          state?.deviceConfig.tenant,
          event.id,
          configState.api_endpoints
        )
          .then(() => {
            updateEventsGlobal(state).then(() => {
              eventHandler("success", "Successfully checked out.");
              setProcessingAction(false);
            });
          })
          .catch((error) => {
            eventHandler("error", "Couldn't check out. Please try again.");
            setProcessingAction(false);
          });
      }}
    >
      <Text
        style={{
          ...tailwind(
            `w-full text-white font-inter pt-[3px] font-bold ${
              processingAction ? "opacity-0" : ""
            }`
          ),
          ...Platform.select({
            web: tailwind("text-xl"),
            android: tailwind("text-base"),
          }),
        }}
      >
        Check out
      </Text>
      {processingAction && (
        <Pulse
          style={tailwind("absolute left-12 top-2 mx-auto w-[50px]")}
          color="#ffffff"
        ></Pulse>
      )}
    </TouchableOpacity>
  );
};
