import api from "./api";
import { Endpoints } from "../../models/Endpoints";
import Tenant from "../../models/Tenant";

export async function eventsEndpoint(
  tenant: Tenant,
  space_id: number,
  endpoints: Endpoints
) {
  return api.get(
    endpoints.meeting_room_app_endpoints.list_of_events
      .replace("[tenant_subdomain]", tenant.subdomain)
      .replace("[space_id]", `${space_id}`)
  );
}
