import api from "./api";
import { Endpoints } from "../../models/Endpoints";

export async function versionControlEndpoint(
  endpoints: Endpoints,
  platform: string,
  running_version: string
) {
  return api.get(
    `${endpoints.tablet_endpoints.version_control}?platform=${platform}&running_version=${running_version}`
  );
}
