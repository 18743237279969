import React from "react";
import { Platform, Text, View } from "react-native";
import { useTailwind } from "tailwind-rn/dist";
import DoubleCheck from "../../assets/img/DoubleCheckIcon";
import { CheckinButton } from "../atoms/CheckinButton";

export const UpcomingEvent = ({
  event,
  style = "",
  eventHandler,
  updateEventsGlobal,
}) => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind(`${style} w-full p-8 flex flex-row justify-between`)}>
      <View style={tailwind("flex flex-col")}>
        <Text
          style={{
            ...tailwind("text-white font-inter"),
            ...Platform.select({
              web: tailwind("text-base font-normal"),
              android: tailwind("text-sm font-light"),
            }),
          }}
        >
          Upcoming meeting
        </Text>
        <Text
          style={{
            ...tailwind("text-white font-inter text-sm font-bold "),
            ...Platform.select({
              web: tailwind("text-base font-bold mt-1 ml-2"),
              android: tailwind("text-sm font-bold mt-2"),
            }),
          }}
        >
          {event.title || "Quick meeting"}
        </Text>
      </View>
      {!event.check_in.done && event.can_checkin && (
        <CheckinButton
          eventHandler={eventHandler}
          event={event}
          updateEventsGlobal={updateEventsGlobal}
        ></CheckinButton>
      )}
      {event.check_in.done && (
        <View
          style={tailwind(
            "flex flex-row bg-[#6DD400]/20 rounded-3xl py-3 px-6 mr-5 mt-2"
          )}
        >
          <DoubleCheck style={tailwind("mt-[2px] mr-2")}></DoubleCheck>
          <Text
            style={tailwind(
              "text-white font-inter font-bold text-base text-[#7BBA39]"
            )}
          >
            Checked in
          </Text>
        </View>
      )}
    </View>
  );
};
