import api from "./api";
import { Endpoints } from "../../models/Endpoints";

export async function isTabletActiveEndpoint(
  tablet_id: number,
  endpoints: Endpoints
) {
  return api.get(
    `${endpoints.tablet_endpoints.is_tablet_active}?tablet_id=${tablet_id}`
  );
}
