// Libraries
import React, { useEffect, useState } from "react";
import {
  FlatList,
  SafeAreaView,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useTailwind } from "tailwind-rn";

// Logic-related
import { getListOfTenants } from "../shared/services";
import { Tenant } from "../shared/models/SpacetiEntities";
import { useActivation } from "../shared/contexts/ActivationContext";
import { useUtility } from "../shared/contexts/UtilityContext";

// UI-related
import { ActivationHeader } from "../components/organisms";
import { TabletSetupVisualExplanationBlock } from "../components/organisms/TabletSetupVisualExplanationBlock";
import { ActivationProcessControls } from "../components/organisms/ActivationProcessControls";
import { Circle } from "react-native-animated-spinkit";
import Check from "../assets/img/Check";

const Tenants = ({ navigation }: any) => {
  const tailwind = useTailwind();
  const { activationState, activationStateActions } = useActivation();
  const { utilityState } = useUtility();

  const [selectedItem, setSelectedItem] = useState<Tenant>({});
  const [listOfTenants, setListOfTenants] = useState<Array<Tenant>>([]);

  const initiateListOfTenants = async () => {
    // TODO: Move api endpoints retrieval to the interceptor
    if (activationState?.token && utilityState.apiEndpoints) {
      const listOfAvailableTenants = await getListOfTenants(
        utilityState.apiEndpoints
      );
      setListOfTenants(listOfAvailableTenants);
      setSelectedItem(listOfAvailableTenants[0]);
    }
  };

  useEffect(() => {
    const unsubscribeFocus = navigation.addListener("focus", () => {
      initiateListOfTenants();
    });
    return unsubscribeFocus;
  }, [navigation, activationState]);

  useEffect(() => {
    if (!activationState?.stateLoading) {
      initiateListOfTenants();
    }
  }, [activationState?.stateLoading]);

  useEffect(() => {
    if (activationState?.tenant) {
      navigation.navigate("BuildingsScreen");
    }
  }, [activationState?.tenant]);

  return (
    <SafeAreaView style={tailwind("h-full bg-neutral")}>
      <ActivationHeader
        navigation={navigation}
        slug="tenant"
        text="Tenant Setup"
      ></ActivationHeader>
      <View style={tailwind(`flex flex-row w-full mt-24`)}>
        <TabletSetupVisualExplanationBlock
          step="tenant"
          title="Tablet setup"
          text={
            "Select a tenant from the list on the right to set up this tablet and connect it to the tenant"
          }
        ></TabletSetupVisualExplanationBlock>
        <ListOfTenants
          listOfTenants={listOfTenants}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        ></ListOfTenants>
      </View>
      <ActivationProcessControls
        confirmAction={() => {
          activationStateActions({
            type: "UPDATE_ACTIVATION_STATE",
            payload: { tenant: selectedItem },
          });
        }}
      ></ActivationProcessControls>
    </SafeAreaView>
  );
};

const ListOfTenants = ({
  listOfTenants,
  selectedItem,
  setSelectedItem,
}: any) => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind("flex flex-col w-3/5")}>
      <Text
        style={tailwind("font-inter text-3xl font-bold text-neutral-black")}
      >
        Select Tenant
      </Text>
      {listOfTenants.length > 0 ? (
        <FlatList
          data={listOfTenants}
          renderItem={({ item, index }: any) => {
            return (
              <TouchableOpacity
                onPress={() => {
                  setSelectedItem(item);
                }}
                style={tailwind(
                  `w-full w-11/12 bg-white justify-center rounded-xl p-5 pl-6 mt-4 ${
                    item.id === selectedItem?.id
                      ? "border-2 border-aquamarine"
                      : ""
                  }`
                )}
              >
                <Text
                  style={tailwind(
                    "text-white font-inter text-xl font-bold text-neutral-black text-left"
                  )}
                >
                  {item.name}
                </Text>
                {item.id === selectedItem?.id && (
                  <Check style={tailwind("absolute right-6")}></Check>
                )}
              </TouchableOpacity>
            );
          }}
        />
      ) : (
        <View style={tailwind("items-center pt-36 pr-36")}>
          <Circle size={48} color="#8891ab"></Circle>
        </View>
      )}
    </View>
  );
};

export default Tenants;
