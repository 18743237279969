import { Text, View, FlatList, TouchableOpacity } from "react-native";
import { useTailwind } from "tailwind-rn";

import DateTime from "../molecules/DateTime";

const Sidebar = ({ events }) => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind("h-full bg-mra-black")}>
      <DateTime></DateTime>
      <Events events={events}></Events>
    </View>
  );
};

export default Sidebar;

const Events = ({ events }) => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind("grow flex-1")}>
      <Text
        style={tailwind("text-3xl font-inter font-semibold text-white px-9")}
      >
        Today’s Schedule
      </Text>
      {events?.length > 0 ? (
        <FlatList
          data={events}
          style={tailwind("pl-9 mt-9")}
          renderItem={({ item }) => {
            return (
              <View style={tailwind("flex flex-row mb-6")}>
                <View style={tailwind("flex flex-col w-[65px]")}>
                  <Text style={tailwind("text-white text-lg font-opensans")}>
                    {item.start}
                  </Text>
                  <Text style={tailwind("text-white text-lg font-opensans")}>
                    {item.end}
                  </Text>
                </View>
                <View
                  style={tailwind(
                    `bg-${item.status} w-[5px] rounded-lg h-full`
                  )}
                ></View>
                <View style={tailwind(`flex flex-col pl-4`)}>
                  <Text
                    numberOfLines={1}
                    style={tailwind(
                      "text-white text-xl font-opensans font-bold"
                    )}
                  >
                    {item.title}
                  </Text>
                  <Text style={tailwind("text-white text-lg font-opensans")}>
                    {item.host}
                  </Text>
                </View>
              </View>
            );
          }}
          contentContainerStyle={{
            flexGrow: 1,
          }}
          // initialScrollIndex={events.length - 1}
        />
      ) : (
        <Text
          style={tailwind(
            "text-xl font-opensans font-normal text-gray px-9 pt-9"
          )}
        >
          No scheduled meetings
        </Text>
      )}
    </View>
  );
};
