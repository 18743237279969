import { Text, View } from "react-native";
import { useTailwind } from "tailwind-rn/dist";
import CheckboxThin from "../../assets/img/CheckboxThin";
import ErrorThin from "../../assets/img/ErrorThin";

export const InfoPopup = ({ data }) => {
  const tailwind = useTailwind();
  let boxWidth = 0;
  let boxHeight = 0;

  switch (data?.size) {
    case "big":
      boxWidth = 700;
      boxHeight = 400;
      break;

    case "fullscreen":
      boxWidth = 2000;
      boxHeight = 2000;
      break;

    default:
      boxWidth = 430;
      boxHeight = 186;
      break;
  }

  return (
    <>
      <View
        style={tailwind(
          `absolute w-full justify-center items-center h-[110%] bg-black/50`
        )}
      >
        <View style={tailwind(`w-[${boxWidth}px] h-[${boxHeight}px]`)}>
          <View
            style={tailwind(
              "w-full h-full bg-mra-black rounded-lg justify-center items-center"
            )}
          >
            {data?.type === "success" ? (
              <CheckboxThin
                style={tailwind("w-[120px]")}
                // accentColor="#6DD400"
              ></CheckboxThin>
            ) : data?.type === "error" ? (
              <ErrorThin style={tailwind("w-[70px]")}></ErrorThin>
            ) : (
              <></>
            )}
            <Text
              style={tailwind(
                "font-inter text-xl font-bold text-white text-center pt-6"
              )}
            >
              {data?.message}
            </Text>
          </View>
        </View>
      </View>
    </>
  );
};
