import axios from "axios";
import { ActivationContextState } from "../models/ActivationContext";
import { getValueFor, save } from "../utilities/storage";
import { refreshToken } from "./auth0Endpoints";

const instance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  async (config) => {
    const activationConfig: ActivationContextState = await getValueFor(
      "activation_config"
    );

    if (activationConfig?.token) {
      config.headers["Authorization"] = "Bearer " + activationConfig?.token;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const config = err.config;

    if (config.url.indexOf("/proxy/") < 0 && err.response) {
      if (err.response.status === 401 && !config._retry) {
        config._retry = true;
        try {
          const activationConfig: ActivationContextState = await getValueFor(
            "activation_config"
          );
          const activationUtility = await getValueFor("activation_utility");
          const rs = await refreshToken(
            activationUtility.auth0ClientId,
            activationConfig.refresh_token
          );
          const { access_token } = rs.data;

          console.log("access_token", access_token);

          activationConfig.token = access_token;
          await save("activation_config", activationConfig, true);
          config.headers["Authorization"] = `Bearer ${access_token}`;
          return instance(config);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);

export default instance;
