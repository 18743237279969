import React, { useState, useEffect } from "react";
import { Platform, View, Text } from "react-native";
import { useTailwind } from "tailwind-rn/dist";
import { CheckinButton } from "../atoms/CheckinButton";
import { CheckoutButton } from "../atoms/CheckoutButton";
import { ExtendButton } from "../atoms/ExtendButton";
import { ExtendEventActions } from "../molecules/ExtendEventAction";
export const CurrentEventActions = ({
  event,
  upcomingEvent,
  style = "",
  eventHandler,
  updateEventsGlobal,
}) => {
  const tailwind = useTailwind();

  const smallStep = 15;
  const bigStep = 15;

  const [remainingAvailableTime, setRemainingAvailableTime] = useState(0);
  const [extendEventActions, setExtendEventActions] = useState(false);

  useEffect(() => {
    const upcoming_event_start_at = new Date(upcomingEvent?.start_at);
    const current_event_end_at = new Date(event?.end_at);

    if (upcomingEvent) {
      console.log(
        "there is upcoming event",
        upcoming_event_start_at,
        current_event_end_at
      );
      setRemainingAvailableTime(
        Math.abs(
          Number(upcoming_event_start_at) - Number(current_event_end_at)
        ) /
          1000 /
          60
      );
    } else {
      setRemainingAvailableTime(bigStep);
    }
  }, [upcomingEvent]);

  return (
    <View style={tailwind(`p-9 flex flex-col mt-10 ${style}`)}>
      {event.can_checkin && (
        <Text
          style={{
            ...tailwind("text-white font-inter font-bold text-sm pb-3"),
            ...Platform.select({
              web: tailwind("text-base pb-2"),
              android: tailwind("text-sm pb-3"),
            }),
          }}
        >
          Please check-in now
        </Text>
      )}
      {!event.can_checkin && !extendEventActions && !event.is_about_to_start && (
        <Text
          style={{
            ...tailwind("text-white font-inter font-bold text-sm pb-3"),
            ...Platform.select({
              web: tailwind("text-base pb-2"),
              android: tailwind("text-sm pb-3"),
            }),
          }}
        >
          Check out?
        </Text>
      )}
      {extendEventActions && (
        <Text
          style={{
            ...tailwind("text-white font-inter font-bold text-sm pb-3"),
            ...Platform.select({
              web: tailwind("text-base pb-2"),
              android: tailwind("text-sm pb-3"),
            }),
          }}
        >
          How much time would you like to add?
        </Text>
      )}
      <View style={tailwind("flex flex-row")}>
        {event.can_checkin && (
          <CheckinButton
            eventHandler={eventHandler}
            event={event}
            updateEventsGlobal={updateEventsGlobal}
          ></CheckinButton>
        )}
        {!event.can_checkin &&
          !extendEventActions &&
          !event.is_about_to_start && (
            <CheckoutButton
              eventHandler={eventHandler}
              event={event}
              updateEventsGlobal={updateEventsGlobal}
            ></CheckoutButton>
          )}
        {!event.can_checkin &&
          !extendEventActions &&
          !event.is_about_to_start &&
          (remainingAvailableTime > smallStep || !event.is_quick_meeting) &&
          remainingAvailableTime > 0 && (
            <ExtendButton
              action={() => {
                setExtendEventActions(true);
              }}
              event={event}
            ></ExtendButton>
          )}
      </View>
      {extendEventActions && (
        <ExtendEventActions
          currentEvent={event}
          cancelAction={() => {
            setExtendEventActions(false);
          }}
          eventHandler={eventHandler}
          upcomingEvent={upcomingEvent}
          updateEventsGlobal={updateEventsGlobal}
        ></ExtendEventActions>
      )}
    </View>
  );
};
