// Libraries
import React, { useEffect, useState } from "react";
import {
  FlatList,
  SafeAreaView,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useTailwind } from "tailwind-rn";

// Logic-related
import { getListOfSpaces } from "../shared/services";
import { useActivation } from "../shared/contexts/ActivationContext";
import { useUtility } from "../shared/contexts/UtilityContext";

// UI-related
import { ActivationHeader } from "../components/organisms";
import { TabletSetupVisualExplanationBlock } from "../components/organisms/TabletSetupVisualExplanationBlock";
import { ActivationProcessControls } from "../components/organisms/ActivationProcessControls";
import { Circle } from "react-native-animated-spinkit";
import Check from "../assets/img/Check";

const Spaces = ({ navigation }: any) => {
  const tailwind = useTailwind();
  const { activationState, activationStateActions } = useActivation();
  const { utilityState } = useUtility();

  const [listOfSpaces, setListOfSpaces] = useState([]);
  const [selectedSpace, setSelectedSpace] = useState({});

  const initiateListOfSpaces = async () => {
    if (activationState?.floor && utilityState?.apiEndpoints) {
      const listOfAvailableSpaces = await getListOfSpaces(
        utilityState?.apiEndpoints,
        activationState?.tenant,
        activationState?.floor?.id
      );
      setListOfSpaces(listOfAvailableSpaces);
      setSelectedSpace(listOfAvailableSpaces[0]);
    }
  };

  useEffect(() => {
    const unsubscribeFocus = navigation.addListener("focus", () => {
      initiateListOfSpaces();
    });
    return unsubscribeFocus;
  }, [navigation, activationState]);

  useEffect(() => {
    if (!activationState?.stateLoading) {
      initiateListOfSpaces();
    }
  }, [activationState?.stateLoading]);

  useEffect(() => {
    if (activationState?.space) {
      navigation.push("FinishSetupScreen");
    }
  }, [activationState?.space]);

  return (
    <SafeAreaView style={tailwind("h-full bg-neutral")}>
      <ActivationHeader
        navigation={navigation}
        slug="space"
        text="Space Setup"
      ></ActivationHeader>
      <View style={tailwind(`flex flex-row w-full mt-24`)}>
        <TabletSetupVisualExplanationBlock
          step="space"
          title={"Tablet setup"}
          text={
            "Select a space from the list on the right to set up this tablet and connect it to the space"
          }
        ></TabletSetupVisualExplanationBlock>
        <ListOfSpaces
          listOfSpaces={listOfSpaces}
          setSelectedSpace={setSelectedSpace}
          selectedSpace={selectedSpace}
        ></ListOfSpaces>
      </View>

      <ActivationProcessControls
        confirmAction={() => {
          activationStateActions({
            type: "UPDATE_ACTIVATION_STATE",
            payload: { space: selectedSpace },
          });
        }}
      ></ActivationProcessControls>
    </SafeAreaView>
  );
};

export default Spaces;

const ListOfSpaces = ({
  listOfSpaces,
  setSelectedSpace,
  selectedSpace,
}: any) => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind("flex flex-col w-3/5")}>
      <Text
        style={tailwind("font-inter text-3xl font-bold text-neutral-black")}
      >
        Select Space
      </Text>
      {listOfSpaces.length > 0 ? (
        <FlatList
          style={tailwind("mt-4 max-h-[425px]")}
          data={listOfSpaces}
          renderItem={({ item, index }) => {
            return (
              <TouchableOpacity
                onPress={() => {
                  setSelectedSpace(item);
                }}
                style={tailwind(
                  `w-full w-11/12 bg-white justify-center rounded-xl p-5 pl-6 mb-4 ${
                    item.id === selectedSpace?.id
                      ? "border-2 border-aquamarine"
                      : ""
                  }`
                )}
              >
                <Text
                  style={tailwind(
                    "text-white font-inter text-xl font-bold text-neutral-black text-left"
                  )}
                >
                  {item.title}
                </Text>
                {item.id === selectedSpace?.id && (
                  <Check style={tailwind("absolute right-6")}></Check>
                )}
              </TouchableOpacity>
            );
          }}
        />
      ) : (
        <View style={tailwind("items-center pt-36 pr-36")}>
          <Circle size={48} color="#8891ab"></Circle>
        </View>
      )}
    </View>
  );
};
