import React from "react";
import { Platform, View, Text } from "react-native";
import { useTailwind } from "tailwind-rn";
import Tablet from "../../assets/img/Tablet";

export const TabletSetupVisualExplanationBlock = ({ step, title, text }) => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind("flex flex-col w-2/5 items-center px-10")}>
      <Tablet></Tablet>
      <Text
        style={tailwind("font-inter text-xl font-bold text-neutral-black pt-5")}
      >
        {title}
      </Text>
      <Text
        style={{
          ...tailwind("font-inter text-base text-gray pt-1 px-8 text-center"),
          ...Platform.select({
            ios: tailwind("font-semibold"),
            android: tailwind("font-bold"),
          }),
        }}
      >
        {text}
      </Text>
    </View>
  );
};
