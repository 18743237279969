import React, {
  createContext,
  useReducer,
  useMemo,
  useContext,
  useState,
  useEffect,
} from "react";
import { save, remove, getValueFor } from "../services/storage";
import { ConfigContextData, ConfigContextState } from "../models/ConfigState";
import { getEndpoints } from "../services/api/endpointsEndpoint";
import { Endpoints } from "../models/Endpoints";

export const ConfigContext = createContext({} as ConfigContextData);

// TODO: Rename into ApiProvider / ApiContext
export const ConfigProvider = ({ children }: any) => {
  let initialState: ConfigContextState = {
    api_endpoints: null,
    auth0ClientId: "cf1UV6965ZeJqhYTkFDRX7MOFX7BiC3V",
  };
  const [state, setState] = useState(initialState);

  const updateEndpoints = () =>
    getEndpoints().then(async (endpoints: Endpoints) => {
      if (endpoints) {
        await save("api_endpoints", endpoints, true);
        setState({
          ...state,
          api_endpoints: endpoints,
        });
      } else {
        getValueFor("api_endpoints").then((endpoints: Endpoints) => {
          if (endpoints) {
            setState({
              ...state,
              api_endpoints: endpoints,
            });
          }
        });
      }
    });

  useEffect(() => {
    updateEndpoints();

    let endpointsInterval = setInterval(() => {
      updateEndpoints();
    }, 1000 * 60 * 15);

    getValueFor("auth0ClientId").then((auth0ClientId: string) => {
      if (!auth0ClientId) {
        save("auth0ClientId", state.auth0ClientId, false);
      }
    });

    return () => {
      clearInterval(endpointsInterval);
    };
  }, []);

  // const configContext = {
  //   set_api_endpoints: async (data: any) => {

  //   },
  // };

  return (
    <ConfigContext.Provider
      value={{
        configState: state,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export function useConfig() {
  const context = useContext(ConfigContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
}
