import * as React from "react";
import Svg, { Path } from "react-native-svg";

function ArrowBack(props) {
  return (
    <Svg
      width={30}
      height={24}
      viewBox="0 0 30 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.724 11.057L11 .781l1.886 1.886-8 8h23.448a1.333 1.333 0 110 2.666H4.886l8 8L11 23.22.724 12.943a1.332 1.332 0 010-1.886z"
        fill="#0B1C37"
      />
    </Svg>
  );
}

export default ArrowBack;
