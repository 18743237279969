import * as React from "react";
import Svg, { Rect, Path } from "react-native-svg";

function Tablet(props) {
  return (
    <Svg
      width={272}
      height={187}
      viewBox="0 0 272 187"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Rect width={272} height={186.579} rx={17.9835} fill="#8891AB" />
      <Rect
        x={10.1139}
        y={8.99146}
        width={251.769}
        height={168.595}
        rx={13.4876}
        fill="#F2F3F7"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M115.017 63.692v26.975h5.995V66.689h29.972v14.986h5.995V63.692a2.995 2.995 0 00-2.997-2.998h-35.967a2.995 2.995 0 00-2.998 2.998zm53.951 20.98h-26.975a2.995 2.995 0 00-2.998 2.998v38.964h-5.994V99.659a2.995 2.995 0 00-2.997-2.998h-26.976a2.995 2.995 0 00-2.997 2.998v29.972a2.995 2.995 0 002.997 2.997h65.94a2.995 2.995 0 002.997-2.997V87.669a2.995 2.995 0 00-2.997-2.997zm-47.956 38.964h-8.992v-5.994h8.992v5.994zm-8.992-11.988h8.992v-5.995h-8.992v5.995zm47.956 11.988h-8.992v-5.994h8.992v5.994zm-8.992-11.988h8.992v-5.995h-8.992v5.995zm8.992-11.99h-8.992v-5.994h8.992v5.995z"
        fill="#E5E8F2"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M188.198 106.896a14.67 14.67 0 01-3.841-9.919c0-8.132 6.594-14.726 14.726-14.726 8.132 0 14.726 6.594 14.726 14.726 0 3.722-1.385 7.224-3.842 9.919a1.027 1.027 0 101.519 1.384 16.715 16.715 0 004.377-11.303c0-9.267-7.513-16.78-16.78-16.78-9.267 0-16.781 7.513-16.781 16.78 0 4.24 1.579 8.234 4.378 11.303a1.028 1.028 0 101.518-1.384z"
        fill="#26D6E6"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M191.479 152.529c2.555 3.625 3.527 5.338 3.771 7.118h31.023l4.676-32.727a3.132 3.132 0 00-3.1-3.574 3.768 3.768 0 00-3.767 3.752v.7a1.027 1.027 0 11-2.054 0v-.676-.008-2.74a3.767 3.767 0 10-7.534 0v2.055a1.027 1.027 0 01-2.055 0v-4.795a3.768 3.768 0 00-7.534 0v3.425a1.027 1.027 0 01-2.055 0v-1.362-23.98a3.767 3.767 0 00-7.534 0V136.017a1.028 1.028 0 11-2.055 0v-7.191h-4.452a3.082 3.082 0 00-3.082 3.082v9.589c0 1.686.776 3.632 2.327 6.097.373.593.76 1.174 1.253 1.881l.532.755 1.64 2.299zm13.426-35.334V99.717a5.822 5.822 0 00-11.644 0v27.054h-4.452a5.137 5.137 0 00-5.137 5.137v9.589c0 2.161.904 4.427 2.642 7.191l.317.495.39.59c.27.403.566.831.91 1.318l1.584 2.221.542.77c2.497 3.576 3.204 5.015 3.204 6.592 0 .568.46 1.028 1.027 1.028h32.876c.512 0 .945-.376 1.018-.882l4.801-33.609a5.186 5.186 0 00-5.134-5.919 5.797 5.797 0 00-3.967 1.561 5.824 5.824 0 00-9.589-2.74 5.825 5.825 0 00-9.388-2.918z"
        fill="#3B4764"
      />
    </Svg>
  );
}

export default Tablet;
