// Libraries
import React, { useEffect, useState } from "react";
import {
  FlatList,
  SafeAreaView,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useTailwind } from "tailwind-rn";

// Logic-related
import { getListOfFloors } from "../shared/services";
import { useUtility } from "../shared/contexts/UtilityContext";
import { useActivation } from "../shared/contexts/ActivationContext";

// UI-related
import { ActivationHeader } from "../components/organisms";
import { TabletSetupVisualExplanationBlock } from "../components/organisms/TabletSetupVisualExplanationBlock";
import { ActivationProcessControls } from "../components/organisms/ActivationProcessControls";
import { Circle } from "react-native-animated-spinkit";
import Check from "../assets/img/Check";

const Floors = ({ navigation }: any) => {
  const tailwind = useTailwind();
  const { activationState, activationStateActions } = useActivation();
  const { utilityState } = useUtility();
  const [listOfFloors, setListOfFloors] = useState([]);
  const [selectedFloor, setSelectedFloor] = useState({});

  const initiateListOfFloors = async () => {
    if (activationState?.building && utilityState?.apiEndpoints) {
      const listOfAvailableFloors = await getListOfFloors(
        utilityState.apiEndpoints,
        activationState?.token,
        activationState?.tenant,
        activationState?.building?.id
      );
      setListOfFloors(listOfAvailableFloors);
      setSelectedFloor(listOfAvailableFloors[0]);
    }
  };

  useEffect(() => {
    const unsubscribeFocus = navigation.addListener("focus", () => {
      initiateListOfFloors();
    });
    return unsubscribeFocus;
  }, [navigation, activationState]);

  useEffect(() => {
    if (!activationState?.stateLoading && listOfFloors.length === 0) {
      initiateListOfFloors();
    }
  }, [activationState?.stateLoading]);

  useEffect(() => {
    if (activationState?.floor) {
      navigation.navigate("SpacesScreen");
    }
  }, [activationState?.floor]);

  return (
    <SafeAreaView style={tailwind("h-full bg-neutral")}>
      <ActivationHeader
        navigation={navigation}
        slug="floor"
        text="Floor Setup"
      ></ActivationHeader>
      <View style={tailwind(`flex flex-row w-full mt-24`)}>
        <TabletSetupVisualExplanationBlock
          step="floor"
          title={"Tablet setup"}
          text={
            "Select a floor from the list on the right to set up this tablet and connect it to the floor"
          }
        ></TabletSetupVisualExplanationBlock>
        <ListOfFloors
          listOfFloors={listOfFloors}
          setSelectedFloor={setSelectedFloor}
          selectedFloor={selectedFloor}
        ></ListOfFloors>
      </View>

      <ActivationProcessControls
        confirmAction={() => {
          activationStateActions({
            type: "UPDATE_ACTIVATION_STATE",
            payload: { floor: selectedFloor },
          });
        }}
      ></ActivationProcessControls>
    </SafeAreaView>
  );
};

export default Floors;

const ListOfFloors = ({
  listOfFloors,
  setSelectedFloor,
  selectedFloor,
}: any) => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind("flex flex-col w-3/5")}>
      <Text
        style={tailwind("font-inter text-3xl font-bold text-neutral-black")}
      >
        Select Floor
      </Text>
      {listOfFloors.length > 0 ? (
        <FlatList
          data={listOfFloors}
          renderItem={({ item, index }) => {
            return (
              <TouchableOpacity
                onPress={() => {
                  setSelectedFloor(item);
                }}
                style={tailwind(
                  `w-full w-11/12 bg-white justify-center rounded-xl p-5 pl-6 mt-4 ${
                    item.id === selectedFloor?.id
                      ? "border-2 border-aquamarine"
                      : ""
                  }`
                )}
              >
                <Text
                  style={tailwind(
                    "text-white font-inter text-xl font-bold text-neutral-black text-left"
                  )}
                >
                  {item.title}
                </Text>
                {item.id === selectedFloor?.id && (
                  <Check style={tailwind("absolute right-6")}></Check>
                )}
              </TouchableOpacity>
            );
          }}
        />
      ) : (
        <View style={tailwind("items-center pt-36 pr-36")}>
          <Circle size={48} color="#8891ab"></Circle>
        </View>
      )}
    </View>
  );
};
