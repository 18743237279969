import * as React from "react";
import Svg, { Path } from "react-native-svg";

function DoubleCheck(props) {
  return (
    <Svg
      width={24}
      height={18}
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 11.59l4.378 5.107a2 2 0 003.115-.096L18.829 1.59l-2-1L5.663 12.596 1.829 9.59 0 11.59zm23.829-7h-5v2h5v-2zm-9 5h9v2h-9v-2zm9 5h-12v2h12v-2z"
        fill="#6DD400"
      />
    </Svg>
  );
}

export default DoubleCheck;
