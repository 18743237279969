import axios from "axios";
import { Endpoints } from "../../models/Endpoints";

export async function requestToken(
  deviceCode: string,
  clientId: string,
  endpoints: Endpoints
) {
  const tokenObject = await axios.post(
    `https://${endpoints.tablet_endpoints.authDomain}/oauth/token`,
    {
      grant_type: "urn:ietf:params:oauth:grant-type:device_code",
      device_code: deviceCode,
      client_id: clientId,
    }
  );
  return tokenObject.data;
}

export async function requestDeviceCode(
  clientId: string,
  endpoints: Endpoints
) {
  const codeObject: any = await axios
    .post(
      `https://${endpoints?.tablet_endpoints?.authDomain}/oauth/device/code`,
      {
        audience: "https://spaceti-api",
        scope: "offline_access",
        client_id: clientId,
      },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      }
    )
    .catch((error) => console.log(error));
  return codeObject.data;
}

export function refreshToken(clientId: string, refreshToken: string) {
  return axios.post(`https://auth.spaceti.net/proxy/oauth/refresh`, {
    grant_type: "refresh_token",
    client_id: clientId,
    refresh_token: refreshToken,
  });
}
