import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React, { useContext, useEffect, useState } from "react";
import {
  AuthentificationScreen,
  BuildingsScreen,
  TenantsScreen,
  FloorsScreen,
  SpacesScreen,
  FinishSetupScreen,
} from "./scenes";
import { ActivationContext } from "./shared/contexts/ActivationContext";

import { DeviceEventEmitter } from "react-native";
import { CancelSetupConfirm } from "./components/organisms/CancelSetupConfirm";
import { navigationRef } from "./shared/utilities/RootNavigation";

export const ActivationRouter = () => {
  const TabletActivationStack = createNativeStackNavigator();

  const [confirm, setConfirm] = useState(false);

  useEffect(() => {
    DeviceEventEmitter.addListener(
      "event.toggleCancelSetupConfirm",
      (state: boolean) => {
        setConfirm(state);
      }
    );
  }, []);

  return (
    <>
      <NavigationContainer ref={navigationRef}>
        <TabletActivationStack.Navigator
          initialRouteName="AuthentificationScreen"
          screenOptions={{ headerShown: false }}
        >
          <TabletActivationStack.Screen
            name="AuthentificationScreen"
            component={AuthentificationScreen}
          />
          <TabletActivationStack.Screen
            name="TenantsScreen"
            component={TenantsScreen}
          />
          <TabletActivationStack.Screen
            name="BuildingsScreen"
            component={BuildingsScreen}
          />
          <TabletActivationStack.Screen
            name="FloorsScreen"
            component={FloorsScreen}
          />
          <TabletActivationStack.Screen
            name="SpacesScreen"
            component={SpacesScreen}
          />
          <TabletActivationStack.Screen
            name="FinishSetupScreen"
            component={FinishSetupScreen}
          />
        </TabletActivationStack.Navigator>
      </NavigationContainer>
      {confirm && <CancelSetupConfirm></CancelSetupConfirm>}
    </>
  );
};
