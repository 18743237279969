import { pad, pluralize } from "../services/datetime";

export const getRemainingTimeText = (deadline: Date) => {
  const now = new Date();
  const remainingTime = Math.abs(Number(deadline) - Number(now)) / 1000 / 60; //in mins
  let remainingHours = Math.floor(remainingTime / 60);
  let remainingMinutes = Math.round(remainingTime % 60);
  if (remainingMinutes === 60) {
    remainingHours++;
    remainingMinutes = 0;
  }
  const remainingHoursText = ` ${remainingHours} ${pluralize(
    remainingHours,
    "hour",
    "hours"
  )}`;
  const remainingMinutesText = ` ${remainingMinutes} ${pluralize(
    remainingMinutes,
    "minute",
    "minutes"
  )}`;
  const remainingTimeText = `${remainingHours > 0 ? remainingHoursText : ""}${
    remainingMinutes > 0 ? remainingMinutesText : ""
  }`;

  return [remainingTimeText, remainingHoursText, remainingMinutesText];
};
