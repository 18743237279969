import { useTailwind } from "tailwind-rn/dist";
import { Platform, Text, TouchableOpacity, View } from "react-native";

export const ExtendButton = ({ event, action }) => {
  const tailwind = useTailwind();
  return (
    <TouchableOpacity
      style={{
        ...tailwind("bg-[#242424] p-4 rounded-lg text-center ml-2"),
        ...Platform.select({
          web: tailwind("w-[200px]"),
          android: tailwind("w-[170px]"),
        }),
      }}
      onPress={() => {
        action();
      }}
    >
      <Text
        style={{
          ...tailwind("w-full text-white font-inter text-base pt-1 font-bold"),
          ...Platform.select({
            web: tailwind("text-xl"),
            android: tailwind("text-base"),
          }),
        }}
      >
        Extend meeting
      </Text>
    </TouchableOpacity>
  );
};
