import React, { useContext, useEffect, useState } from "react";
import { Platform } from "react-native";
import SplashScreen from "react-native-splash-screen";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { NavigationContainer } from "@react-navigation/native";

import DeviceActivationModule from "./ActivationModule";
import { HomeScreen } from "./scenes";
import { AuthContext } from "./shared/contexts/AuthContext";
import { ConfigContext } from "./shared/contexts/ConfigContext";

export const Router = () => {
  const { state, stateActions } = useContext(AuthContext);
  const { configState } = useContext(ConfigContext);

  const MeetingRoomStack = createNativeStackNavigator();

  useEffect(() => {
    if (configState.api_endpoints && Platform.OS !== "web") SplashScreen.hide();
  }, [configState.api_endpoints]);

  return !state?.stateLoading && state?.userToken && state?.deviceConfig ? (
    <NavigationContainer>
      <MeetingRoomStack.Navigator
        initialRouteName="HomeScreen"
        screenOptions={{ headerShown: false }}
      >
        <MeetingRoomStack.Screen name="HomeScreen" component={HomeScreen} />
      </MeetingRoomStack.Navigator>
    </NavigationContainer>
  ) : configState.api_endpoints ? (
    <DeviceActivationModule
      apiEndpoints={configState.api_endpoints}
      auth0ClientId="cf1UV6965ZeJqhYTkFDRX7MOFX7BiC3V"
      deviceEntityLevel="space"
      setDeviceConfig={(deviceConfig, token, refreshToken) => {
        stateActions.activateTablet(token, refreshToken, deviceConfig);
      }}
    ></DeviceActivationModule>
  ) : (
    <></>
  );
};
