import React, { useContext } from "react";
import { AuthContext } from "../../shared/contexts/AuthContext";
import { useTailwind } from "tailwind-rn/dist";
import { View, Text } from "react-native";

export const SpaceTitle = () => {
  const { state } = useContext(AuthContext);
  const tailwind = useTailwind();
  return (
    <View style={tailwind("")}>
      <Text style={tailwind("text-3xl font-inter font-bold text-white p-8")}>
        {state.deviceConfig.space.title}
      </Text>
      <View
        style={tailwind(
          "absolute bg-expired text-expired bg-available/20 text-available bg-busy/20 text-busy bg-checkin/20 text-checkin opacity-0 h-[186px] w-[430px] h-[400px] h-[2000px] w-[2000px]"
        )}
      ></View>
    </View>
  );
};
