import { useNavigation } from "@react-navigation/native";
import React, { useContext, useEffect } from "react";
import {
  Platform,
  View,
  Text,
  TouchableOpacity,
  DeviceEventEmitter,
} from "react-native";
import { useTailwind } from "tailwind-rn";
import { ActivationContext } from "../../shared/contexts/ActivationContext";
import * as RootNavigation from "../../shared/utilities/RootNavigation";

export const CancelSetupConfirm = ({}: any) => {
  const tailwind = useTailwind();
  const { activationStateActions } = useContext(ActivationContext);

  useEffect(() => {
    return () => {
      DeviceEventEmitter.removeAllListeners("event.mapMarkerSelected");
    };
  }, []);

  const dismiss = () => {
    DeviceEventEmitter.emit("event.toggleCancelSetupConfirm", false);
  };

  const cancelSetup = () => {
    (async () => {
      await activationStateActions({
        type: "RESET_ACTIVATION_STATE",
      });
      DeviceEventEmitter.emit("event.toggleCancelSetupConfirm", false);
      RootNavigation.navigate("AuthentificationScreen", null);
    })();
  };

  return (
    <View
      style={tailwind(
        `absolute w-full justify-center items-center h-[110%] bg-neutral-black/75`
      )}
    >
      <View style={tailwind("w-[430px] h-[236px]")}>
        <View style={tailwind("w-full h-full bg-white rounded-lg")}>
          <Text
            style={tailwind(
              "font-inter text-xl font-bold text-neutral-black px-5 pt-5 pb-2"
            )}
          >
            Cancel setup
          </Text>
          <Text
            style={tailwind(
              "font-inter text-sm font-semibold text-gray py-2 px-5"
            )}
          >
            This will restore the settings of the tablet and all the steps will
            be reverted.
          </Text>
          <Text
            style={tailwind("font-inter text-sm font-bold text-red py-2 px-5")}
          >
            This action cannot be undone!
          </Text>
          <View
            style={tailwind(
              "flex flex-row justify-end items-end w-full px-5 mt-6"
            )}
          >
            <TouchableOpacity
              onPress={dismiss}
              style={tailwind(
                `w-24 h-10 justify-center rounded-lg bg-light-gray mr-3`
              )}
            >
              <Text
                style={tailwind(
                  `text-white font-inter text-base font-semibold text-neutral-black text-center`
                )}
              >
                Close
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={cancelSetup}
              style={tailwind(`w-32 h-10 justify-center rounded-lg bg-red`)}
            >
              <Text
                style={tailwind(
                  `text-white font-inter text-base font-semibold text-white text-center`
                )}
              >
                Cancel setup
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </View>
  );
};
