import { useNavigation } from "@react-navigation/native";
import React, { useEffect } from "react";
import {
  Platform,
  View,
  Text,
  TouchableOpacity,
  DeviceEventEmitter,
} from "react-native";
import { useTailwind } from "tailwind-rn";

export const ActivationProcessControls = ({
  confirmAction,
  text = "Confirm",
}: any) => {
  const tailwind = useTailwind();

  useEffect(() => {
    return () => {
      DeviceEventEmitter.removeAllListeners("event.mapMarkerSelected");
    };
  }, []);

  const cancelSetup = () => {
    DeviceEventEmitter.emit("event.toggleCancelSetupConfirm", true);
  };

  return (
    <View
      style={tailwind(
        "absolute w-full bottom-0 items-end justify-between h-28 flex-row px-16 pb-8"
      )}
    >
      <TouchableOpacity
        onPress={cancelSetup}
        style={tailwind("w-48 h-14 justify-center rounded-lg")}
      >
        <Text
          style={{
            ...tailwind(
              " text-white font-inter text-xl font-bold text-neutral-black text-left "
            ),
            ...Platform.select({
              ios: tailwind("pr-1"),
              android: tailwind("pb-1 pr-2"),
            }),
          }}
        >
          Cancel setup
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={confirmAction}
        style={tailwind("w-48 h-14 bg-aquamarine justify-center rounded-lg")}
      >
        <Text
          style={{
            ...tailwind(
              " text-white font-inter text-xl font-bold text-white text-center"
            ),
            ...Platform.select({
              ios: tailwind("pr-1"),
              android: tailwind("pb-1 pr-2"),
            }),
          }}
        >
          {text}
        </Text>
      </TouchableOpacity>
    </View>
  );
};
