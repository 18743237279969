// Libraries
import React, { useContext, useEffect, useState } from "react";
import {
  FlatList,
  Platform,
  SafeAreaView,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useTailwind } from "tailwind-rn";

// Logic-related
import {
  ActivationContext,
  useActivation,
} from "../shared/contexts/ActivationContext";

// UI-related
import { ActivationHeader } from "../components/organisms";
import { TabletSetupVisualExplanationBlock } from "../components/organisms/TabletSetupVisualExplanationBlock";
import { ActivationProcessControls } from "../components/organisms/ActivationProcessControls";
import { Circle } from "react-native-animated-spinkit";
import Check from "../assets/img/Check";
import { Loading } from "../components/organisms/Loading";
import { registerTabletEndpoint } from "../shared/services/registerTabletEndpoint";
import { useUtility } from "../shared/contexts/UtilityContext";

const FinishSetup = ({ navigation }: any) => {
  const tailwind = useTailwind();
  const { activationState, activationStateActions } = useActivation();
  const { utilityState } = useUtility();

  const [deviceConfig, setDeviceConfig] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setDeviceConfig([
      {
        title: "Tenant",
        value: activationState.tenant.name,
      },
      {
        title: "Building",
        value: activationState.building.label,
      },
      {
        title: "Floor",
        value: activationState.floor.title,
      },
      {
        title: "Space",
        value: activationState.space.title,
      },
    ]);
  }, []);

  const handleFinishSetup = () => {
    setLoading(true);
    const tabletTitle = `${activationState.tenant.name}/${activationState.building.label}/${activationState.floor.title}/${activationState.space.title}`;
    registerTabletEndpoint(
      tabletTitle,
      activationState.tenant.id,
      activationState.building.id,
      activationState.floor.id,
      activationState.space.id,
      JSON.stringify(activationState.deviceInfo),
      "meeting_room_app",
      activationState.token,
      utilityState.apiEndpoints
    )
      .then((res) => {
        activationStateActions({
          type: "UPDATE_ACTIVATION_STATE",
          payload: {
            tabletId: res.data.tablet_id,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <SafeAreaView style={tailwind("h-full bg-neutral")}>
      <ActivationHeader
        navigation={navigation}
        slug="finish"
        text="Finish Setup"
      ></ActivationHeader>
      <View style={tailwind(`flex flex-row w-full mt-24`)}>
        <TabletSetupVisualExplanationBlock
          step="space"
          title={"Connect to space"}
          text={"This tablet will be connected to the selected space"}
        ></TabletSetupVisualExplanationBlock>
        <DeviceConfiguration deviceConfig={deviceConfig}></DeviceConfiguration>
      </View>
      <ActivationProcessControls
        text={"Confirm & Finish"}
        confirmAction={handleFinishSetup}
      ></ActivationProcessControls>
      {loading && <Loading></Loading>}
    </SafeAreaView>
  );
};

export default FinishSetup;

const DeviceConfiguration = ({ deviceConfig }: any) => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind("flex flex-col w-3/5")}>
      <Text
        style={tailwind("font-inter text-3xl font-bold text-neutral-black")}
      >
        Tablet Settings
      </Text>
      {deviceConfig.length > 0 ? (
        <FlatList
          data={deviceConfig}
          renderItem={({ item, index }) => {
            return (
              <TouchableOpacity
                style={tailwind(
                  `flex flex-row justify-between w-full w-11/12 bg-white rounded-xl p-5 pl-12 mt-4`
                )}
              >
                <Check
                  color="#00C669"
                  style={tailwind(
                    "absolute left-6 top-0 bottom-0 mt-auto mb-auto"
                  )}
                ></Check>
                <Text
                  style={tailwind(
                    "text-white font-inter text-xl font-bold text-neutral-black text-left pl-6"
                  )}
                >
                  {item.title}
                </Text>
                <Text
                  style={tailwind(
                    "text-white font-inter text-xl font-bold text-neutral-black text-left pl-6"
                  )}
                >
                  {item.value}
                </Text>
              </TouchableOpacity>
            );
          }}
        />
      ) : (
        <View style={tailwind("items-center pt-36 pr-36")}>
          <Circle size={48} color="#8891ab"></Circle>
        </View>
      )}
    </View>
  );
};
