// Libraries
import React, { useEffect, useState } from "react";
import {
  FlatList,
  SafeAreaView,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useTailwind } from "tailwind-rn";

// Logic-related
import { getListOfBuildings } from "../shared/services";
import { useActivation } from "../shared/contexts/ActivationContext";
import { useUtility } from "../shared/contexts/UtilityContext";

// UI-related
import { ActivationHeader } from "../components/organisms";
import { TabletSetupVisualExplanationBlock } from "../components/organisms/TabletSetupVisualExplanationBlock";
import { ActivationProcessControls } from "../components/organisms/ActivationProcessControls";
import { Circle } from "react-native-animated-spinkit";
import Check from "../assets/img/Check";

const Buildings = ({ navigation }: any) => {
  const tailwind = useTailwind();
  const { activationState, activationStateActions } = useActivation();
  const { utilityState } = useUtility();
  const [listOfBuildings, setListOfBuildings] = useState([]);
  const [selectedBuilding, setSelectedBuilding] = useState({});

  const initiateListOfBuilding = async () => {
    if (activationState?.token && utilityState.apiEndpoints) {
      const listOfAvailableBuildings = await getListOfBuildings(
        utilityState.apiEndpoints,
        activationState?.token,
        activationState?.tenant
      );
      setListOfBuildings(listOfAvailableBuildings);
      setSelectedBuilding(listOfAvailableBuildings[0]);
    }
  };

  useEffect(() => {
    const unsubscribeFocus = navigation.addListener("focus", () => {
      initiateListOfBuilding();
    });
    return unsubscribeFocus;
  }, [navigation, activationState]);

  useEffect(() => {
    if (!activationState?.stateLoading) {
      initiateListOfBuilding();
    }
  }, [activationState?.stateLoading]);

  useEffect(() => {
    if (activationState?.building) {
      navigation.navigate("FloorsScreen");
    }
  }, [activationState?.building]);

  return (
    <SafeAreaView style={tailwind("h-full bg-neutral")}>
      <ActivationHeader
        navigation={navigation}
        slug="building"
        text="Building Setup"
      ></ActivationHeader>
      <View style={tailwind(`flex flex-row w-full mt-24`)}>
        <TabletSetupVisualExplanationBlock
          step="building"
          title={"Tablet setup"}
          text={
            "Select a building from the list on the right to set up this tablet and connect it to the building"
          }
        ></TabletSetupVisualExplanationBlock>
        <ListOfBuildings
          listOfBuildings={listOfBuildings}
          setSelectedBuilding={setSelectedBuilding}
          selectedBuilding={selectedBuilding}
        ></ListOfBuildings>
      </View>

      <ActivationProcessControls
        confirmAction={() => {
          activationStateActions({
            type: "UPDATE_ACTIVATION_STATE",
            payload: { building: selectedBuilding },
          });
        }}
      ></ActivationProcessControls>
    </SafeAreaView>
  );
};

export default Buildings;

const ListOfBuildings = ({
  listOfBuildings,
  setSelectedBuilding,
  selectedBuilding,
}: any) => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind("flex flex-col w-3/5")}>
      <Text
        style={tailwind("font-inter text-3xl font-bold text-neutral-black")}
      >
        Select Building
      </Text>
      {listOfBuildings.length > 0 ? (
        <FlatList
          data={listOfBuildings}
          renderItem={({ item, index }) => {
            return (
              <TouchableOpacity
                onPress={() => {
                  setSelectedBuilding(item);
                }}
                style={tailwind(
                  `w-full w-11/12 bg-white justify-center rounded-xl p-5 pl-6 mt-4 ${
                    item.id === selectedBuilding?.id
                      ? "border-2 border-aquamarine"
                      : ""
                  }`
                )}
              >
                <Text
                  style={tailwind(
                    "text-white font-inter text-xl font-bold text-neutral-black text-left"
                  )}
                >
                  {item.label}
                </Text>
                {item.id === selectedBuilding?.id && (
                  <Check style={tailwind("absolute right-6")}></Check>
                )}
              </TouchableOpacity>
            );
          }}
        />
      ) : (
        <View style={tailwind("items-center pt-36 pr-36")}>
          <Circle size={48} color="#8891ab"></Circle>
        </View>
      )}
    </View>
  );
};
