import * as React from "react";
import Svg, { Path } from "react-native-svg";

function ArrowNext(props) {
  return (
    <Svg
      width={13}
      height={12}
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M6.549 11.42l-1.17-1.159 3.505-3.505H.367V5.062h8.517l-3.505-3.5L6.549.399l5.511 5.511-5.511 5.512z"
        fill="#6A7591"
      />
    </Svg>
  );
}

export default ArrowNext;
