import api from "./api";
import { Endpoints } from "../../models/Endpoints";

export async function updateTabletInfo(
  tablet_id: number,
  endpoints: Endpoints,
  running_version?: string
) {
  return api.put(`${endpoints.tablet_endpoints.update_tablet_info}`, {
    tablet_id: tablet_id,
    running_version: running_version,
  });
}
