import { useTailwind } from "tailwind-rn/dist";
import React, { useContext, useState } from "react";
import { AuthContext } from "../../shared/contexts/AuthContext";
import { ConfigContext } from "../../shared/contexts/ConfigContext";
import { Platform, Text, TouchableOpacity } from "react-native";
import { Pulse } from "react-native-animated-spinkit";
import { checkinEndpoint } from "../../shared/services/api/checkinEndpoint";

export const CheckinButton = ({ event, eventHandler, updateEventsGlobal }) => {
  const tailwind = useTailwind();
  const { state } = useContext(AuthContext);
  const { configState } = useContext(ConfigContext);
  const [processingAction, setProcessingAction] = useState(false);
  return (
    <TouchableOpacity
      disabled={processingAction}
      style={{
        ...tailwind(
          "bg-checkin p-4 rounded-lg w-[104px] text-center  h-[67px] relative"
        ),
        ...Platform.select({
          web: tailwind("w-[140px] pl-4"),
          android: tailwind("w-[125px] pl-6"),
        }),
      }}
      onPress={() => {
        setProcessingAction(true);
        checkinEndpoint(
          state?.deviceConfig.tenant,
          event.id,
          configState.api_endpoints
        )
          .then(() => {
            updateEventsGlobal(state).then(() => {
              setProcessingAction(false);
              eventHandler("success", "Successfully checked in");
            });
          })
          .catch((error) => {
            eventHandler("error", "Checkin unsuccessfull");
            setProcessingAction(false);
          });
      }}
    >
      <Text
        style={{
          ...tailwind(
            `w-full text-white font-inter pt-[3px] font-bold ${
              processingAction ? "opacity-0" : ""
            }`
          ),
          ...Platform.select({
            web: tailwind("text-xl"),
            android: tailwind("text-base"),
          }),
        }}
      >
        Check in
      </Text>
      {processingAction && (
        <Pulse
          style={{
            ...tailwind("absolute left-7 top-2 mx-auto w-[50px]"),
            ...Platform.select({
              web: tailwind("left-12"),
              android: tailwind("left-10"),
            }),
          }}
          color="#ffffff"
        ></Pulse>
      )}
    </TouchableOpacity>
  );
};
