import * as React from "react";
import Svg, { Path } from "react-native-svg";

function Attention(props) {
  return (
    <Svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 0C8.972 0 0 8.972 0 20s8.972 20 20 20 20-8.972 20-20S31.028 0 20 0zm2.742 8.333l-1.075 15h-3.334l-1.013-15h5.422zm-2.742 25A3.332 3.332 0 0116.667 30 3.332 3.332 0 0120 26.667 3.332 3.332 0 0123.333 30 3.332 3.332 0 0120 33.333z"
        fill="#8891AB"
      />
    </Svg>
  );
}

export default Attention;
