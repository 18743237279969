import { default as DeviceInfoRN } from "react-native-device-info";
import { Platform } from "react-native";
import { DeviceInfo } from "../models/DeviceInfo";
import { browserName, fullBrowserVersion } from "react-device-detect";

export async function getDeviceInfo() {
  let deviceInfo = {} as DeviceInfo;

  if (Platform.OS === "android") {
    deviceInfo.platform = "android";
    deviceInfo.androidId = await DeviceInfoRN.getAndroidId();
    deviceInfo.apiLevel = await DeviceInfoRN.getApiLevel();
    deviceInfo.availableLocationProviders =
      await DeviceInfoRN.getAvailableLocationProviders();
    deviceInfo.baseOS = await DeviceInfoRN.getBaseOs();
    deviceInfo.osBuildId = await DeviceInfoRN.getBuildId();
    deviceInfo.brand = DeviceInfoRN.getBrand();
    deviceInfo.isCameraPresent = await DeviceInfoRN.isCameraPresent();
    deviceInfo.carrier = await DeviceInfoRN.getCarrier();
    deviceInfo.deviceId = await DeviceInfoRN.getDeviceId();
    deviceInfo.deviceType = await DeviceInfoRN.getDeviceType();
    deviceInfo.lastUpdateTime = await DeviceInfoRN.getLastUpdateTime();
    deviceInfo.model = await DeviceInfoRN.getModel();
    deviceInfo.powerState = await DeviceInfoRN.getPowerState();
    deviceInfo.systemAvailableFeatures = await (
      await DeviceInfoRN.getSystemAvailableFeatures()
    ).join();
    deviceInfo.systemVersion = await DeviceInfoRN.getSystemVersion();
    deviceInfo.totalMemory = await DeviceInfoRN.getTotalMemory();
    deviceInfo.usedMemory = await DeviceInfoRN.getUsedMemory();
    deviceInfo.userAgent = await DeviceInfoRN.getUserAgent();
    deviceInfo.isLocaltionEnabled = await DeviceInfoRN.isLocationEnabled();
    deviceInfo.isTablet = await DeviceInfoRN.isTablet();
  }

  if (Platform.OS === "ios") {
    deviceInfo.platform = "ios";
    deviceInfo.availableLocationProviders =
      await DeviceInfoRN.getAvailableLocationProviders();
    deviceInfo.osBuildId = await DeviceInfoRN.getBuildId();
    deviceInfo.brand = DeviceInfoRN.getBrand();
    deviceInfo.carrier = await DeviceInfoRN.getCarrier();
    deviceInfo.deviceId = await DeviceInfoRN.getDeviceId();
    deviceInfo.deviceType = await DeviceInfoRN.getDeviceType();
    deviceInfo.model = await DeviceInfoRN.getModel();
    deviceInfo.powerState = await DeviceInfoRN.getPowerState();
    deviceInfo.systemVersion = await DeviceInfoRN.getSystemVersion();
    deviceInfo.totalMemory = await DeviceInfoRN.getTotalMemory();
    deviceInfo.usedMemory = await DeviceInfoRN.getUsedMemory();
    deviceInfo.userAgent = await DeviceInfoRN.getUserAgent();
    deviceInfo.isLocaltionEnabled = await DeviceInfoRN.isLocationEnabled();
    deviceInfo.isTablet = await DeviceInfoRN.isTablet();
  }

  if (Platform.OS === "web") {
    deviceInfo.platform = "web";
    deviceInfo.browser = browserName;
    deviceInfo.baseOS = await DeviceInfoRN.getBaseOs();
    deviceInfo.isCameraPresent = await DeviceInfoRN.isCameraPresent();
    deviceInfo.totalMemory = await DeviceInfoRN.getTotalMemory();
    deviceInfo.usedMemory = await DeviceInfoRN.getUsedMemory();
    deviceInfo.userAgent = await DeviceInfoRN.getUserAgent();
    deviceInfo.isLocaltionEnabled = await DeviceInfoRN.isLocationEnabled();
    deviceInfo.browser = browserName;
    deviceInfo.browserVersion = fullBrowserVersion;
  }

  return deviceInfo;
}
