import React, { useContext, useState, useEffect } from "react";
import { Platform, Text, TouchableOpacity, View } from "react-native";
import { useTailwind } from "tailwind-rn/dist";
import { AuthContext } from "../../shared/contexts/AuthContext";
import { ConfigContext } from "../../shared/contexts/ConfigContext";
import { quickEventEndpoint } from "../../shared/services/api/quickEventEndpoint";
import { Pulse } from "react-native-animated-spinkit";
import MinusIcon from "../../assets/img/MinusIcon";
import PlusIcon from "../../assets/img/PlusIcon";

export const QuickEventActions = ({
  upcomingEvent,
  eventHandler,
  updateEventsGlobal,
}) => {
  const tailwind = useTailwind();
  const { state } = useContext(AuthContext);
  const { configState } = useContext(ConfigContext);

  const smallStep = 15;
  const bigStep = 15;
  const minDurationValue = 5;
  const maxDurationValue = 60 * 5;

  const [durationValue, setDurationValue] = useState(0);
  const [processingAction, setProcessingAction] = useState(false);
  const [remainingAvailableTime, setRemainingAvailableTime] = useState(0);

  useEffect(() => {
    const now = new Date();
    const start_at = new Date(upcomingEvent?.start_at);

    setRemainingAvailableTime(
      Math.abs(Number(start_at) - Number(now)) / 1000 / 60
    ); //in mins
  }, [upcomingEvent]);

  useEffect(() => {
    if (upcomingEvent && remainingAvailableTime > bigStep)
      setDurationValue(bigStep);
    else if (upcomingEvent && remainingAvailableTime > smallStep)
      setDurationValue(smallStep);
    else if (upcomingEvent && remainingAvailableTime < smallStep)
      setDurationValue(Math.round(remainingAvailableTime));
    else if (!upcomingEvent) {
      setDurationValue(bigStep);
      setRemainingAvailableTime(maxDurationValue);
    }
  }, [remainingAvailableTime]);

  const increaseDurationValue = () => {
    if (durationValue + bigStep <= remainingAvailableTime) {
      setDurationValue(durationValue + bigStep);
    } else if (durationValue + smallStep <= remainingAvailableTime) {
      setDurationValue(durationValue + smallStep);
    } else if (remainingAvailableTime > 0) {
      setDurationValue(Math.floor(remainingAvailableTime));
    }
  };

  const decreaseDurationValue = () => {
    if (durationValue % smallStep === 0) {
      console.log("was even increase. decrease by smallStep");
      if (durationValue - smallStep >= minDurationValue) {
        setDurationValue(durationValue - smallStep);
      }
    } else {
      console.log("was odd increase", durationValue % smallStep);

      if (
        durationValue - smallStep - (durationValue % smallStep) >=
        minDurationValue
      ) {
        setDurationValue(
          durationValue - smallStep - (durationValue % smallStep)
        );
      } else if (
        durationValue - (durationValue % smallStep) >=
        minDurationValue
      ) {
        setDurationValue(durationValue - (durationValue % smallStep));
      }
    }
  };

  return (
    <View style={tailwind("p-9 flex flex-col mt-[140px]")}>
      <Text
        style={{
          ...tailwind("text-white font-inter font-bold text-sm pb-3"),
          ...Platform.select({
            web: tailwind("text-base"),
            android: tailwind("text-sm"),
          }),
        }}
      >
        Create a quick meeting now
      </Text>
      <View style={tailwind("flex flex-row")}>
        <TouchableOpacity
          disabled={processingAction || durationValue === 0}
          style={{
            ...tailwind(
              "bg-available rounded-lg w-[240px] h-[67px] flex flex-row justify-between"
            ),
            ...Platform.select({
              web: tailwind("w-[260px]"),
              android: tailwind("w-[240px]"),
            }),
          }}
          onPress={() => {
            setProcessingAction(true);
            quickEventEndpoint(
              state?.deviceConfig.tenant,
              state?.deviceConfig.space.id,
              durationValue,
              configState.api_endpoints
            )
              .then(() => {
                eventHandler("success", "Quick meeting successfully created");
                updateEventsGlobal(state).then(() => {
                  setProcessingAction(false);
                });
              })
              .catch((error) => {
                console.log(error.errorMessage);
                setProcessingAction(false);
                eventHandler(
                  "error",
                  error.errorMessage || "Could not create quick meeting"
                );
              });
          }}
        >
          <Text
            style={{
              ...tailwind(
                `text-white pt-5 pl-4 font-inter  font-bold ${
                  processingAction ? "opacity-0" : ""
                }`
              ),
              ...Platform.select({
                web: tailwind("text-xl"),
                android: tailwind("text-base"),
              }),
            }}
          >
            Meet now
          </Text>
          {!processingAction && (
            <Text
              style={{
                ...tailwind(
                  "text-white font-inter font-light rounded-lg bg-[#19a054] p-2 m-3"
                ),
                ...Platform.select({
                  web: tailwind("text-lg"),
                  android: tailwind("text-base"),
                }),
              }}
            >
              {durationValue} minutes
            </Text>
          )}
          {processingAction && (
            <Pulse
              style={tailwind("absolute left-[100px] top-3 mx-auto w-[50px]")}
              color="#ffffff"
            ></Pulse>
          )}
        </TouchableOpacity>
        <TouchableOpacity
          disabled={processingAction}
          style={tailwind(
            "bg-[#242424] rounded-lg flex flex-col justify-center content-center px-6 ml-2"
          )}
          onPress={() => {
            decreaseDurationValue();
          }}
        >
          <MinusIcon></MinusIcon>
        </TouchableOpacity>
        <TouchableOpacity
          disabled={
            processingAction ||
            durationValue + smallStep > maxDurationValue ||
            durationValue + bigStep > maxDurationValue
          }
          style={tailwind(
            "bg-[#242424] rounded-lg flex flex-col justify-center content-center px-6 ml-2"
          )}
          onPress={() => {
            increaseDurationValue();
          }}
        >
          <PlusIcon></PlusIcon>
        </TouchableOpacity>
      </View>
    </View>
  );
};
