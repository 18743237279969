import { Tenant } from "../models/SpacetiEntities";
import api from "./api";
import { Endpoints } from "../models/Endpoints";

export async function getListOfTenants(endpoints: Endpoints) {
  let listOfTenants: Array<Tenant> = await api
    .get(endpoints.tablet_endpoints.tenants)
    .then((resp) => resp.data)
    .catch((err) => {
      console.log(err);
    });

  return listOfTenants;
}
