import React, { useContext } from "react";
import { AuthContext } from "../../shared/contexts/AuthContext";
import { useTailwind } from "tailwind-rn/dist";
import { View, Text } from "react-native";

export const SpaceStatus = ({ status, text }) => {
  const { state } = useContext(AuthContext);
  const tailwind = useTailwind();
  return (
    <View
      style={{
        ...tailwind(`self-start`),
      }}
    >
      <Text
        style={tailwind(
          `bg-${status}/20 text-5xl text-available leading-[4rem] font-inter font-bold text-${status} mx-8 mt-10 px-6 py-2 rounded-xl`
        )}
      >
        {text}
      </Text>
    </View>
  );
};
