import api from "./api";
import { Endpoints } from "../../models/Endpoints";

export async function checkoutEndpoint(
  tenant: any,
  booking_id: string,
  endpoints: Endpoints
) {
  return api.post(
    endpoints.meeting_room_app_endpoints.checkout
      .replace("[tenant_subdomain]", tenant.subdomain)
      .replace("[booking_id]", booking_id),
    {}
  );
}
