import * as React from "react";
import Svg, { Path } from "react-native-svg";

function Check(props) {
  return (
    <Svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10C19.984 4.484 15.516.016 10 0zM8.333 14.512L3.822 10 5 8.822l3.333 3.333L15 5.488l1.178 1.179-7.845 7.845z"
        fill={props.color ? props.color : "#26D6E6"}
      />
    </Svg>
  );
}

export default Check;
