import React, { useContext, useEffect } from "react";
import utilities from "./tailwind.json";

import { UtilityProvider } from "./shared/contexts/UtilityContext";
import { ActivationProvider } from "./shared/contexts/ActivationContext";
import { TailwindProvider, Utilities } from "tailwind-rn";

import { ActivationRouter } from "./ActivationRouter";
import { useFonts } from "@expo-google-fonts/inter";

const DeviceActivationModule = ({
  apiEndpoints,
  deviceEntityLevel,
  setDeviceConfig,
  auth0ClientId,
}) => {
  // TODO: Check how fonts behave on web vs native
  // const [appLoaded] = useFonts({
  //   Inter: require("./assets/fonts/Inter.ttf"),
  //   OpenSans: require("./assets/fonts/OpenSans.ttf"),
  // });

  return (
    <UtilityProvider apiEndpoints={apiEndpoints} auth0ClientId={auth0ClientId}>
      <ActivationProvider
        deviceEntityLevel={deviceEntityLevel}
        setDeviceConfig={setDeviceConfig}
      >
        <TailwindProvider
          {...{
            utilities: utilities,
          }}
        >
          <ActivationRouter></ActivationRouter>
        </TailwindProvider>
      </ActivationProvider>
    </UtilityProvider>
  );
};

export default DeviceActivationModule;
export * from "./shared/models/SpacetiEntities";
