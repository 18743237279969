import api from "./api";
import { Endpoints } from "../../models/Endpoints";

export async function prolongEventEndpoint(
  tenant: any,
  booking_id: string,
  duration: number,
  endpoints: Endpoints
) {
  return api.post(
    endpoints.meeting_room_app_endpoints.prolong_event
      .replace("[tenant_subdomain]", tenant.subdomain)
      .replace("[booking_id]", booking_id),
    {
      duration: duration,
    }
  );
}
